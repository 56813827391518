import React, { useContext, useEffect, useRef } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import PhoneInput from 'react-phone-input-2'
import { useState } from 'react';
import { Country, State, City } from 'country-state-city';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS, URL_SIGNATURE } from '../../URL/URL';

import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2/dist/sweetalert2';
import reactSessionApi from 'react-session-api';
function EditionMembre(props) {
    const { visibleProfile, setVisibleProfile } = useContext(ThemeContext);
    const { id } = useParams();
    const [matricule, setMatricule] = useState("");
    const [imageName, setImageName] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [sexe, setSexe] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [qualification, setQualification] = useState("");
    const [specialite, setSpecialite] = useState("");
    const [experience, setExperience] = useState("");
    const [pays, setPays] = useState('');
    const [ville, setVille] = useState("");
    const [biographie, setBiographie] = useState("");
    const [image, setImage] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [exp, setExp] = useState("");
    const [signature, setSignature] = useState(null);
    const [avatarImage, setAvatarImage] = useState(null);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const navigate = useNavigate();
    const [phone, setPhone] = useState("242");
    const [countries, setCountries] = useState([]);
    const [region, setRegion] = React.useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [departement, setDepartement] = useState('');
    const fileInputRef = useRef(null);
    const [message_user_id, setMessage_user_id] = useState("");
    const [message_matricule, setMessage_matricule] = useState("");
    const [message_nom, setMessage_nom] = useState("");
    const [message_prenom, setMessage_prenom] = useState("");
    const [message_sexe, setMessage_sexe] = useState("");
    const [message_mail, setMessage_mail] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_qualification, setMessage_qualification] = useState("");
    const [message_specialite, setMessage_specialite] = useState("");
    const [message_experience, setMessage_experience] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [message_biographie, setMessage_biographie] = useState("");
    const [message_signature, setMessage_signature] = useState("");
    const [loading, setLoading] = useState(true)
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const useRefImage = useRef(null);
    const [avatarSetImage, setAvatarSetImage] = useState("");
    const [experiences, setExperiences] = useState([
        {
            label: "1 - 2 an(s)",
            value: "1 - 2 an(s)"
        },
        {
            label: "2 - 5 an(s)",
            value: "2 - 5 an(s)"
        },
        {
            label: "5 - 10 an(s)",
            value: "5 - 10 an(s)"
        },
        {
            label: "10 an(s) ou plus",
            value: "10 an(s) ou plus"
        }
    ])

    // const experiences = [
    //     {
    //         label: "1-2 an(s)",
    //         value: "1-2 an(s)"
    //     },
    //     {
    //         label: "2-5 an(s)",
    //         value: "2-5 an(s)"
    //     },
    //     {
    //         label: "5-10 an(s)",
    //         value: "5-10 an(s)"
    //     },
    //     {
    //         label: "10 an(s) ou plus",
    //         value: "10 an(s) ou plus"
    //     }
    // ]
    const {
        boolDF, setBoolDF,
        boolResultat, setBoolResult,
        boolCorbeille, setBoolCorbeille,
        boolAbonnement, setBoolAbonnement,
        boolAgenda, setBoolAgenda,
        boolModalites, setBoolModalites,
        boolStatistiques, setBoolStatistiques,
        boolMcr, setBoolMcr,
        boolNotification, setBoolNotification,
        boolManipulateur, setBoolManipulateur,
        boolContrat, setBoolContrat,
        boolGains, setBoolGains,
        boolExamens, setBoolExamens,
        boolMembres, setBoolMembres,
        boolUsers, setBoolUsers,
        boolSites, setBoolSites
    } = useContext(ThemeContext);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const HandleAvatar = () => {
        useRefImage.current.click();
    };
    const EmailValidation = (email) => {

        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const validationFields = () => {

        if (user_id == "") {

            setMessage_user_id("Veuillez saisir utilisateur");

        }

        if (nom == "") {

            setMessage_nom("Veuillez saisir le nom");

        }

        if (prenom == "") {

            setMessage_prenom("Veuillez saisir le prenom");

        }

        if (email == "") {

            setMessage_mail("Veuillez saisir le mail");

        }

        if (telephone == "") {

            setMessage_telephone("Veuillez saisir le n° de téléphone");

        }

        if (qualification == "") {

            setMessage_qualification("Veuillez saisir la qualification");

        }
        if (specialite == "") {

            setMessage_specialite("Veuillez saisir la spécialité");

        }

        if (experience == "") {

            setMessage_experience("Veuillez saisir expérience");

        }

        if (pays == "") {

            setMessage_pays("Veuillez sélectionner le pays");

        }
        if (departement == "") {

            setMessage_ville("Veuillez sélectionner la ville");

        }
        if (sexe == "") {

            setMessage_sexe("Veuillez sélectionner le sexe !");

        }

    }

    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }

    const handleInputNom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNom(value)
            setMessage_nom("");
        } else {
            setNom("")
        }
    }
    const handleInputPrenom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPrenom(value)
            setMessage_prenom("");
        } else {
            setPrenom("")
        }
    }
    const handleInputSexe = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSexe(value)
            setMessage_sexe("");
        } else {
            setSexe("")
        }
    }
    const handleInputTelephone = (value) => {
        //const value = event.target.value;
        // Use a regular expression to allow only numbers (including negative numbers)
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            // If the input value contains only numbers or negative numbers,
            // update the telephone state with the value.
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            setTelephone("");
            setMessage_telephone("Le n° de téléphone est invalid !");
        }
    }

    const handleInputQualification = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setQualification(value)
            setMessage_qualification("");
        } else {
            setQualification("")
        }
    }

    const handleInputSpecialite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSpecialite(value)
            setMessage_specialite("");
        } else {
            setSpecialite("")
        }
    }
    const handleInputExperience = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setExperience(value)
            setMessage_experience("");
        } else {
            setExperience("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSignature(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleFileChangeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        setAvatarImage(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarSetImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const updateMembre = async () => {
        validationFields();
        const formData = new FormData();

        // const imageFile = new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });
        // const data_membre = {
        //     user_id: user_id,
        //     matricule: matricule,
        //     nom: nom,
        //     prenom: prenom,
        //     sexe: sexe,
        //     email: email,
        //     telephone: telephone,
        //     qualification: qualification,
        //     specialite: specialite,
        //     experience: experience,
        //     pays: pays,
        //     ville: departement,
        //     biographie: biographie,
        //     signature: signature,
        //     photo: avatarSetImage
        // }
        formData.append('user_id', user_id);
        formData.append('matricule', matricule);
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('sexe', sexe);
        formData.append('email', email);
        formData.append('telephone', telephone);
        formData.append('qualification', qualification);
        formData.append('specialite', specialite);
        formData.append('experience', experience);
        formData.append('pays', pays);
        formData.append('ville', ville);
        formData.append('biographie', biographie);
        formData.append('signature', signature);
        formData.append('photo', avatarImage);
        if (nom !== "" && prenom !== "" && sexe !== "" && email !== "" && telephone !== "" && qualification !== "" && specialite !== "" && experience !== "" && pays !== "" && ville !== "") {
            if (EmailValidation(email)) {
                await axios.post(URL_AXIOS + `update_membre/${props.id}`, formData)
                    .then((response) => {
                        if (response.data.status === 200) {
                            setVisibleProfile(false)
                            Swal.fire({
                                title: "Réussi !",
                                text: "La mise à jour a été effectuée avec succès !",
                                icon: 'success'
                            });
                        }

                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

        } else {

        }

    };



    const handleCountryChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setPays(name_get);
            setMessage_mail("");
        } else {
            setPays("")
        }
        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            const phonecode = filter.phonecode;
            setRegion(states);
            // setCode(phonecode)
        });
    };

    const liste_membres_by_id = async () => {
        try {
            await axios.get(URL_AXIOS + `get_membre_by_id/${props.id}`)
                .then(res => {
                    //setUser_id(res.data.user_id);
                    setMatricule(res.data.matricule);
                    setNom(res.data.membres.nom);
                    setPrenom(res.data.membres.prenom);
                    setSexe(res.data.membres.sexe);
                    setEmail(res.data.membres.email);
                    setTelephone(res.data.membres.telephone);
                    setQualification(res.data.membres.qualification);
                    setSpecialite(res.data.membres.specialite);
                    setExperience(res.data.membres.experience);
                    setBiographie(res.data.membres.biographie);

                    // const filterData = experiences.filter((exp) => exp.label === experience);

                    // if (filterData.length > 0) {
                    //     alert(`Filtered Data: ${JSON.stringify(filterData)}`);
                    // } else {
                    //     alert('No matching experience found'+experience);
                    // }

                    setPays(res.data.membres.pays);
                    setVille(res.data.membres.ville)
                    setDepartement(res.data.membres.ville);
                    // setBiographie(res.data.biographie);
                    setAvatarImage(res.data.membres.image)
                    const path = URL_SIGNATURE + res.data.membres.signature;
                    const path_photo = URL_SIGNATURE + res.data.membres.image;
                    res.data.membres.signature !== null ? setImage(path) : setImage("https://img.freepik.com/vecteurs-premium/modele-vectoriel-pour-autocollant-banniere-autocollant-affiche-typographie-etc_98908-478.jpg")
                    res.data.membres.image !== null ? setAvatarSetImage(path_photo) : setAvatarSetImage("")
                    setLoading(false)
                });
        } catch (Error) {

        }

    }
    useEffect(() => {
        liste_membres_by_id();
    }, []);
    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();
        // if (usernameconnected === null || usernameconnected === "" || usernameconnected == undefined) {
        //     navigate('/')
        // }

    }, []);
    return (
        <div class="container-fluid">
            <div className='row'>
                <div className='col col-md-8'>
                    <div class="row" style={{ marginTop: 20 }}>
                        <div className='col col-md-12'>
                            <input type='text' className='form-control' placeholder='Nom(s) *'
                                value={nom} onChange={handleInputNom}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}
                            />
                            {message_nom !== "" ? <label style={{ color: 'red' }}>{message_nom}</label> : ""}
                        
                        </div>

                    </div>
                    <div class="row">

                        <div className='col col-md-12'>

                            <input type='text' className='form-control' placeholder='Prenom(s) *'
                                value={prenom} onChange={handleInputPrenom}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}
                            />
                            {message_prenom !== "" ? <label style={{ color: 'red' }}>{message_prenom}</label> : ""}
                        
                        </div>

                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={sexe} onChange={handleInputSexe}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={""}>Sexe *</option>
                                <option value='M'>M</option>
                                <option value='F'>F</option>
                            </select>
                            {message_sexe !== "" ? <label style={{ color: 'red' }}>{message_sexe}</label> : ""}
                        
                        </div>


                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <input type='text' className='form-control' placeholder='E-mail *'
                                value={email} onChange={handleInputEmail} style={{ fontWeight: 'bolder' }}
                            />
                            {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}
                       
                        </div>

                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <input
                                className='form-control'
                                value={telephone}
                                onChange={(value) => handleInputTelephone(value)}
                                placeholder="Téléphone * "
                                style={{ fontWeight: 'bolder' }}

                            />

                            {message_telephone !== "" ? <label style={{ color: 'red' }}>{message_telephone}</label> : ""}
                        </div>

                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={qualification} onChange={handleInputQualification}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={id !== "" ? qualification : ""}>{id !== "" ? qualification : "Qualification *"}</option>
                                <option value='Médecin'>Médecin</option>

                            </select>
                            {message_qualification !== "" ? <label style={{ color: 'red' }}>{message_qualification}</label> : ""}

                        </div>


                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={specialite} onChange={handleInputSpecialite}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={""}>Spécialité *</option>
                                <option value='Radiologue'>Radiologue</option>

                            </select>
                            {message_specialite !== "" ? <label style={{ color: 'red' }}>{message_specialite}</label> : ""}
                        </div>
                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={experience} onChange={(e) => setExperience(e.target.value)}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={""}>{"Expérience *"}</option>
                                {experiences.map((exp) => (
                                    <option value={exp.label}>{exp.label}</option>
                                ))}

                            </select>

                            {message_experience !== "" ? <label style={{ color: 'red' }}>{message_experience}</label> : ""}
                        </div>
                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={pays} onChange={handleCountryChange}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={id !== "" ? pays : ""}>{id !== "" ? pays : "Sélectionner un pays *"} </option>
                                {countries.map((country) => (
                                    <option key={country.isoCode} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            {message_pays !== "" ? <label style={{ color: 'red' }}>{message_pays}</label> : ""}
                        </div>
                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <select className='form-control' value={departement} onChange={handleInputVille}
                                style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                <option value={id !== "" ? ville : ""}>{id !== "" ? ville : "Sélectionner un pays"}</option>
                                {region.map((region) => (
                                    <option key={region.isoCode} value={region.name}>
                                        {region.name}
                                    </option>
                                ))}
                            </select>
                            {message_departement !== "" ? <label style={{ color: 'red' }}>{message_departement}</label> : ""}
                        </div>
                    </div>
                    <div class="row">
                        <div className='col col-md-12'>
                            <textarea rows={10} className='form-control' placeholder='Biographie' value={biographie} onChange={(e) => setBiographie(e.target.value)}>

                            </textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div className='col col-md-4'>
                            <h5 style={{ marginTop: "40px" }}>Signature</h5>
                        </div>
                        <div className='col col-md-8'>
                            <img onClick={handleClick}
                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                src={image} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                </div>
                <div className='col col-md-4' >
                    <img src={avatarImage!==null ? avatarSetImage: "../../../img/avatar.jpg"}
                        style={{ width: "200px", marginLeft: 15, borderRadius: "10px", marginTop: 22, height: "200px" }}
                        onClick={() => HandleAvatar()}
                        title={'Veuillez cliquer pour sélectionner la photo'}
                    />
                    <input type='file'
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChangeAvatar}
                        ref={useRefImage}
                    />
                </div>
            </div>


            <div class="row">
                <div className='col col-md-12'>
                    <button class="btn btn-primary form-control" type="button"
                        onClick={() => updateMembre()}

                    >Mettre à jour</button>
                </div>
            </div>

        </div>
    );
}

export default EditionMembre;