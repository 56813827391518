import React, { useContext, useEffect, useState, useRef } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import reactSessionApi from 'react-session-api';
import Loading from './Loading';
import { Dialog } from 'primereact/dialog';
import JoditEditor from 'jodit-react';
function Notifications() {
    const { text, setText, datedebut, dossiersEn, setDossiersEn, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis,
        medecinID, setMedecinID,
        notifi, setNotifi,
        getURLFolder, setGetURLFolder,
        dataMembre, setDataMembre,
        notifications, setNotifications,
        userID, setUserID,

    } = useContext(ThemeContext);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const navigate = useNavigate();
    const recordsPerPage = 4;
    //const [notifi, setNotifi] = useState([]);
    const [notifidata, setNotifiData] = useState([]);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = notifications.slice(firstIndex, lastIndex);
    const npage = Math.ceil(notifications.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [nameMembres, setNameMembres] = useState([]);
    //const [dataMembre, setDataMembre] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const dateStart_two = new Date(dateStart).toDateString();
    const dateEnd_two = new Date(dateEnd).toDateString();
    const [cancel, setCancel] = useState(true);
    const [statut_search, setStatut_search] = useState(false);
    const [LoadingState, setLoadingState] = useState(false);
    
    const [loading, setLoading] = useState(true);
    const editorRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [ContentData, setContentData] = useState('');
    const openDetail = async (id) => {
        try {
           
            const response = await axios.get(URL_AXIOS + `get_notifications_by_id/${id}`);
            const data = response.data.notifications;
            if (response.data.status === 200) {
               
                setContentData(data.contenu);
                setVisible(true)

            }
           
        } catch (error) {

        }

    }
    const confirm2 = (id) => {

        Swal.fire({
            title: "Voulez-vous supprimer cette notification ?",
            showCancelButton: true,
            confirmButtonText: "Valider",
            icon: 'question'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                delete_notification(id);
                
                Swal.fire("La notification a été supprimée avec succès !", "", "success");
            } else if (result.isCanceled) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
        /* confirmDialog({
             message: 'Voulez-vous supprimer cette notification ?',
             header: 'Confirmation Suppression ',
             icon: 'pi pi-info-circle',
             acceptClassName: 'p-button-danger',
             accept: () => {
                 delete_notification(id);
                 liste_notification();
             },
             reject: () => {
 
             }
         });*/
    };

    const delete_notification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_notifications/${id}`);
            if (response.data.status === 200) {
                liste_notification(user_id);


            }
        } catch (Error) {

        }

    }
    const toast = useRef(null);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const liste_notification = async (user_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl);
                setLoadingState(true)
            }
        } catch (Error) {

        }
    }

    const fetchMembres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                const mappedMembres = response.data.users_sites.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
                setMembres(mappedMembres);
            }
        } catch (error) {
            console.error("Error fetching membres:", error);
        }
    };
    const handleQueryText = async () => {
        try {
            //const query_name = e.target.value;
            //setQuery(query_name);

            setCurrentPage(1);

            if (query.length > 0) {


                const response = await axios.post(URL_AXIOS + 'recherche_notification_by_objet_or_site', {
                    keyValue: query.toUpperCase()
                });
                if (response.data.status === 200) {
                    setNotifi(response.data.notifications);
                    setStatut_search(true)
                } else {

                }
            } else {
                liste_notification(user_id);
            }
        } catch (error) {
            //liste_notification();
        }
    };

    const recherche_notification = async () => {
        try {

            const response = await axios.post(URL_AXIOS + 'recherche_notification', {
                startDate: dateStart_two,
                endDate: dateEnd_two
            });
            if (response.data.status === 200) {
                setNotifi(response.data.notifications);
                setCancel(false);
            } else {

            }
        } catch (Error) {

        }


    }
    const handleStartDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        setDateStart(selectedDate.toLocaleDateString());
    };

    // When setting dateEnd
    const handleEndDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        setDateEnd(selectedDate.toLocaleDateString());
    };
    const Annuler_me = () => {

        setDateStart('');
        setDateEnd('');
        setCancel(true);
        liste_notification(user_id);
        fetchMembres();
    }
    const handleDownload = (e, si) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = getURLFolder + "/" + si.fichier_notification;
        downloadLink.download = si.fichier_notification;
        downloadLink.target = "_blank";
        downloadLink.click();
    };

    const config = {

        style: {
            position: 'absolute'
        },
        height: 300,
        allowResizeX: false,
        allowResizeY: false,
        autofocus: false,
        toolbarAdaptive: false,
        editorClassName: 'editorComponent',
        hidePoweredByJodit: false,
        zIndex: 0,
        readonly: false,
        //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: false,
        editorCssClass: true,
        triggerChangeEvent: false,
        width: 'auto',
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'fr',
        tabIndex: -1,
        toolbar: false,
        saveModeInStorage: false,
        useSplitMode: false,
        autofocus: false,

        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },

        colorPickerDefaultTab: 'background',
        removeButtons: [],
        disablePlugins: [],
        extraButtons: [],
        sizeLG: 900,
        sizeMD: 700,
        statusbar: false,
        shadowRoot: null,
        //sizeSM: 400,
        //sizeSM: 400,
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'print',
            'speechRecognize'
        ],
        /*buttonsXS: [
            'bold',
            'image', '|',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],*/
        events: {},
        textIcons: false,
    }
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <button type="button" className='btn btn-warning' onClick={() => navigate(`/editionNotification/${data.id_notification}`)} style={{ backgroundColor: "orange" }} >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' className="btn btn-danger" style={{ backgroundColor: "red", marginLeft: 10 }} onClick={() => confirm2(data.id_notification)} >
                    <i className='fa fa-trash'></i>
                </button>
                <button type='button' className="btn btn-info" style={{ marginLeft: 10 }} onClick={() => openDetail(data.id_notification)} >
                    <i className='fa fa-eye'></i>
                </button>
            </div>
        )
    }

    const ActionBodyRecu = (data) => {
        return (
            <div >
                {dataMembre.membres[data.id_notification].length === membres.length ? "Tous les utilisateurs" : dataMembre.membres[data.id_notification]?.map((da, index) => (
                    <p key={index}>{da.name_recep + ","}</p>
                ))}
            </div>
        )
    }
    useEffect(() => {
    
        liste_notification(user_id);
        fetchMembres();
        setDisableAvis(true);
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Dialog header="Message" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visible} style={{ width: '75vw', }} onHide={() => { if (!visible) return; setVisible(false); }}>
            <br /> 
                <JoditEditor
                    ref={editorRef}
                    config={config}
                    tabIndex={1}
                    value={ContentData}


                />
            </Dialog>
           {LoadingState ? <section id="container">
                <ConfirmDialog />
                <Toast ref={toast} />


                <section id="main-content">
                    <section class="wrapper">
                        <div className='row ' style={{ padding: 10, marginTop: 20 }}>

                            <div className="col col-md-1">
                                <Button className='btn btn-info' label='Nouvelle notification'
                                    onClick={() => navigate('/addNotifications')} style={{ height: 35 }}
                                />
                            </div>

                        </div>
                        <div class="row " style={{ padding: 10 }}>
                            <DataTable value={notifications} emptyMessage="Aucune notification disponible !" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem', backgroundColor: "green" }} className='custom-datatable'>
                                <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} field='id_notification' headerStyle={{ marginLeft: "100px" }} style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='objet' header="OBJET" style={{ width: '15%', height: '40px' }}></Column>
                                <Column header="MESSAGE" body={(data) => { return <div style={{ marginLeft: 20 }}>{data.contenu.length >= 20 ? data.contenu.substring(0, 20) + "..." : data.contenu}</div> }} style={{ width: '20%', height: '40px', wordWrap: "break-word" }}></Column>
                                <Column header="ENVOYE PAR" field='name_sender' style={{ width: '20%', height: '40px' }}></Column>
                                <Column header="RECU PAR" body={ActionBodyRecu} style={{ width: '30%', height: '40px' }}></Column>
                                <Column field='created_at' header="DATE" style={{ width: '15%', height: '40px' }}></Column>
                                <Column header="ACTIONS" body={ActionBody} style={{ width: '20%', height: '40px' }}></Column>
                            </DataTable>
                            {/* <i class="fa-solid fa-file-pdf"></i> */}
                            {/* <table class="table table-bordered table-striped table-condensed">
                                <thead>
                                    <tr style={{ backgroundColor: 'white' }}>
                                        <th style={{ color: 'red' }}><center>#</center></th>
                                        <th style={{ color: 'red' }}>OBJET</th>
                                        <th style={{ color: 'red' }}>MESSAGE</th>
                                        <th style={{ color: 'red' }}>EXPEDITEUR</th>
                                        <th style={{ color: 'red' }}>RECEPTEUR</th>
                                        <th style={{ color: 'red' }}>DATE</th>
                                        <th colSpan={3} style={{ width: 30, color: 'red' }}><center>ACTIONS</center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((si, index) => (
                                        <tr key={index}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.objet}</td>
                                            <td>{si.contenu}</td>
                                            <td>{si.name_sender}</td>
                                            <td>
                                                {dataMembre.membres[si.id_notification]?.length === membres.length ? "Tous les utilisateurs" : dataMembre.membres[si.id_notification]?.map((data, index) => (
                                                    <div key={index}>{data.name_recep + ","}</div>
                                                ))}
                                            </td>
                                            <td>{si.created_at}</td>
                                            <td><center><button type='button' onClick={() => navigate(`/editionNotification/${si.id_notification}`)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id_notification)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>

                                            <td><center><button type='button' onClick={(e) => handleDownload(e, si)} className='btn btn-primary'>
                                                <i className='fa fa-download'></i>
                                            </button>
                                            </center></td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}

                            {/* <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={prevPage}
                                        >Prev</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href='#' className='page-link'
                                                    onClick={() => changeCPage(n)}
                                                >{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={nextPage}
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                    </section>
                </section >
                <Footer />
            </section >:<Loading/>}

        </div>
    );
}

export default Notifications;