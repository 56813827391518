import React, { useState, useEffect, useContext } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { MultiSelect } from 'react-multi-select-component';

import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2';
import reactSessionApi from 'react-session-api';

function EditionNotification() {

    const { datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const { id } = useParams();
    const [objet, setObjet] = useState("");
    const [text, setText] = useState("");
    const [selected, setSelected] = useState([]);
    const [membres, setMembres] = useState([]);
    const [options, setOptions] = useState([]);
    const [membres_get, setMembres_get] = useState([]);
    const [membres_data, setMembres_data] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [membreid, setMembreId] = useState(0);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileCheck, setSelectedFileCheck] = useState(null);
    const [selectedFileValue, setSelectedFileValue] = useState(null);
    const [user_id_sender, setUser_id_sender] = useState(11);

    const [statusFichier, setStatusFichier] = useState(false);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setStatusFichier(true);
    };
    const handleSelected = (selected) => {
        setSelected(selected)
    }
    const updateNotificat = async () => {
        try {
            if (objet != "" && text != "") {
                const data_notifi = {
                    objet: objet,
                    contenu: text.replace(/<[^>]*>/g, ''),
                    user_id_sender: user_id,
                    user_id_reception: selected,
                    file: (selectedFile && selectedFile !== "") ? selectedFile : selectedFileValue,
                    statut: statusFichier ? 'Exist ' : 'NotExist'
                }
                const res = await axios.post(URL_AXIOS + `update_notifications/${id}`, data_notifi, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                    },
                });
                if (res.data.status === 200) {

                    navigate('/notifications')
                }else{
                    navigate('/notifications')
                }

            } else {
                Swal.fire({
                    title: "Attention !",
                    text: "Veuillez saisir toutes les informations",
                    icon: "warning",
                
                });
             
            }
        } catch (error) {
            alert("Error saving : " + error.message)
        }

    }

    const liste_membres_by_id = async (membreid) => {

        try {

            await axios.get(URL_AXIOS + `get_membre_by_id_second/${membreid}`)
                .then(res => {
                    const mappedMembr_data = res.data.members.map((item) => ({
                        label: item.name_recep,
                        value: item.id_user_recep
                    }));
                    setSelected(mappedMembr_data);

                });
        } catch (Error) {
            console.log('Error ' + Error)
        }

    }

    const fetchData = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `get_notifications_by_id/${id}`);
            const data = response.data.notifications;
            if (response.data.status === 200) {
                const folderURL = response.data.folderUrl + '/' + data.fichier_notification;
                setText(data.contenu);
                setObjet(data.objet);
                setSelectedFile(data.fichier_notification);
                setSelectedFileCheck(data.fichier_notification)
                setSelectedFileValue(folderURL)
                liste_membres_by_id(id)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleMultiSelectChange = (selected) => {
        setSelected(selected);
    };

    const fetchMembres = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_user_siteByUser/${id}`);
            if (response.data.status === 200) {
                const mappedMembres = response.data.users_sites.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
                setMembres(mappedMembres);

                //alert("Data : "+response.data.users_sites)
            }
        } catch (error) {
            //alert("Data : "+error)
            console.error("Error fetching membres:", error);
        }
    };

    const handleDownload = (e, val) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = val;
        downloadLink.target = "_blank";
        downloadLink.click();
    };


    useEffect(() => {

        fetchData();
        fetchMembres(user_id);
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }

    }, []);

    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper ">

                    <div className='row card' style={{ height: 'auto' }}>
                        <div className="">
                            <form role="form">
                                <div className="form-group">
                                    <div className='col col-md-3'>
                                        <input
                                            type='text'
                                            value={objet}
                                            onChange={(e) => setObjet(e.target.value)}
                                            className='form-control'
                                            placeholder='Objet *'
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='col col-md-4'>
                                        <MultiSelect
                                            options={membres}
                                            value={selected}
                                            filter
                                            onChange={handleMultiSelectChange}
                                            labelledBy="Sélectionner les utilisateurs "
                                            className="custom-multi-select"
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-4'>
                                        <div className="">
                                            <input type="file" accept="application/pdf" onChange={handleFileChange} className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col col-lg-1">

                                        {selectedFileCheck && <i className='fa fa-paperclip'
                                            onClick={(e) => handleDownload(e, selectedFileValue)}
                                            style={{ cursor: 'pointer', fontSize: "30px" }}
                                        ></i>}

                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-12'>
                                        <div className="">
                                            <Editor
                                                value={text}
                                                onTextChange={(e) => setText(e.htmlValue)}
                                                style={{ height: '500px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col col-lg-12">
                                        <button type='button' onClick={updateNotificat} className='btn btn-warning'>Mettre à jour</button>
                                        <button type='button' onClick={() => navigate('/notifications')} className='btn btn-danger ' style={{ marginLeft: 10 }}>Annuler</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default EditionNotification;