import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useRef } from 'react';
import JoditEditor from 'jodit-react';
import { ThemeContext } from '../../context/ContextHeader';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import reactSessionApi from 'react-session-api';
import Loading from './Loading';
function ModelCompteRendu() {
    const navigate = useNavigate();
    const { mcr, setMcr } = useContext(ThemeContext);
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const usernameconnected = sessionStorage.getItem("nom");
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const [titreCompteRendu, setTitreCompteRendu] = useState("");
    const [observation, setObservation] = useState("");
    const [conclusion, setConclusion] = useState("");
    const [resultat, setResultat] = useState("");
    const [reseignementClinique, setReseignementClinique] = useState("");
    const useRefTitre = useRef(null);
    //const [mcr, setMcr] = useState([]);
    const [messageError, setMessageError] = useState(false);
    const [mcrId, setMcrId] = useState('');
    const [statusUpdate, setStatusUpdate] = useState(false)
    const useRefDiv = useRef(null);
    const editorRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [obsGet, setObsGet] = useState('');
    const [visible, setVisible] = useState(false);
    const [LoadingState, setLoadingState] = useState(false);
    const config = {

        style: {
            position: 'absolute'
        },
        height: 300,
        allowResizeX: false,
        allowResizeY: false,
        autofocus: false,
        toolbarAdaptive: false,
        editorClassName: 'editorComponent',
        hidePoweredByJodit: false,
        zIndex: 0,
        readonly: false,
        //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: false,
        editorCssClass: true,
        triggerChangeEvent: false,
        width: 'auto',
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'fr',
        tabIndex: -1,
        toolbar: false,
        saveModeInStorage: false,
        useSplitMode: false,
        autofocus: false,
     
        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },

        colorPickerDefaultTab: 'background',
        removeButtons: [],
        disablePlugins: [],
        extraButtons: [],
        sizeLG: 900,
        sizeMD: 700,
        statusbar: false,
        shadowRoot: null,
        //sizeSM: 400,
        //sizeSM: 400,
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'print',
            'speechRecognize'
        ],
        /*buttonsXS: [
            'bold',
            'image', '|',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],*/
        events: {},
        textIcons: false,
    }
    // const list_mcr = async (id_member) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS +` list_mcr_allByMember/${id_member}`);
    //         if (response.data.status === 200) {
    //             setMcr(response.data.mcr);
    //         }
    //     } catch (Error) {

    //     }
    // }
    const list_mcrLoad = async (id_mcr) => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcr_load/${membre_id}/${id_mcr}`);
            if (response.data.status === 200) {
                if (statusUpdate === false) {

                    setStatusUpdate(true);
                    setTitreCompteRendu(response.data.mcr.titre);
                    // setObservation(response.data.mcr.observation);
                    editorRef.current.value = response.data.mcr.observation;
                    setMcrId(response.data.mcr.id);

                } else {
                    setStatusUpdate(false);
                    setTitreCompteRendu("");
                    setObservation("");
                    setMcrId("")

                }

            } else {
                alert("NOT ")
            }
        } catch (Error) {

        }
    }
    const loadDataUpdate = (index) => {
        setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
        list_mcrLoad(index);
    }
    const addMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;

            if (observation !== "" && titreCompteRendu != "") {
                const data = {
                    titre: titreCompteRendu,
                    observation: observation,
                    statut: '0',
                    id_membre: ''
                }
                const response = await axios.post(URL_AXIOS + 'addMcr', data);
                if (response.data.status === 200) {
                    Swal.fire({
                        title: 'Informations',
                        text: 'Les données ont été enregistrées avec succès !',
                        icon: 'success',

                    });
                    setTitreCompteRendu("");
                    setObservation("");
                    // list_mcr();
                    setMessageError(false)
                } else {
                    alert("Erreur savings")
                }



            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })
                useRefTitre.current.focus();
                setMessageError(true)
            }
        } catch (error) {
            alert("erreur 12" + error)
        }
    }
    const updateMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;

            if (observation !== "" && titreCompteRendu != "") {



                const data = {
                    titre: titreCompteRendu,
                    observation: observation,
                    statut: '0',
                    id_membre: '',
                    id_mcr: mcrId

                }
                const response = await axios.post(URL_AXIOS + 'updateMcr', data);
                if (response.data.status === 200) {
                    Swal.fire({
                        title: 'Réussi !',
                        text: 'Le modèle de compte rendu a été mis à jour avec succès !',
                        icon: 'success',

                    })
                    setTitreCompteRendu("");


                    setObservation("");
                    // list_mcr();
                    setMessageError(false)
                } else {
                    alert("Erreur savings")
                }



            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })
                useRefTitre.current.focus();
                setMessageError(true)
            }
        } catch (error) {
            alert("erreur 12" + error)
        }
    }
    const addMcrConfirmMrc = () => {
        try {
            Swal.fire({
                title: "Enregistrement",
                text: "Êtes-vous sûr de vouloir enregistrer ce compte rendu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {

                    addMcr();
                }
            });
        } catch (error) {

        }
    }
    const deleteMcr = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_mcr/${id}`);
            if (response.data.status === 200) {
                 list_mcr(membre_id);
                setMcrId("");
            }
        } catch (error) {

        }
    }


    const handleChange = (newContent) => {
        setObservation(newContent);
        setObsGet(newContent)
    }

    const deleteConfirmMrc = (id) => {
        try {
            Swal.fire({
                title: "Question !",
                text: "Êtes-vous sûr de vouloir supprimer ce modèle de compte rendu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Réussi !",
                        text: "Le modèle de compte rendu a été supprimé avec succès !",
                        icon: "success"
                    });
                    deleteMcr(id);
                }
            });
        } catch (error) {

        }
    }
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => navigate(`/editionMcr/${data.id}`)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{ marginLeft: 10 }} onClick={() => ApercuModelCompteRendu(data)} className='btn btn-info'>
                    <i className='fa fa-eye'></i>
                </button>
                <button type='button' onClick={() => deleteConfirmMrc(data.id)} style={{ marginLeft: 10 }} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }

    const ActionMembre = (data) => {
        return (
            <div style={{ display: 'flex', padding: 10 }}>
                {data.nom == null ? "Administrateur " : data.nom + " " + data.prenom}
            </div>
        )
    }
    const ApercuModelCompteRendu = (data) => {
        setTitreCompteRendu(data.titre);
        setObservation(data.observation)
        setVisible(true);
    }
    const list_mcr = async (membre_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcrByMember/${membre_id}`);
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
                setLoadingState(true)
            }
        } catch (Error) {

        }
    }
    useEffect(() => {
     list_mcr(membre_id);
     if (usernameconnected === null || usernameconnected === "") {
        navigate('/')
    }
    }, [])
    return (
        <section id="container">
            <Header />
            <Dialog header="Compte rendu" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visible} style={{ width: '75vw', }} onHide={() => { if (!visible) return; setVisible(false); }}>
                <h2 className='text-center' style={{textDecoration:"underline"}}>{titreCompteRendu}</h2>
                <JoditEditor
                    ref={editorRef}
                    config={config}
                    tabIndex={1}
                    value={observation}


                />
            </Dialog>
            <AsideBar username={usernameconnected} />
           {LoadingState ? <section id="main-content">

                <section class="wrapper">
                    <div className='container-fluid'>
                        <div className='row' style={{ marginTop: 10 }}>
                            <div className='col col-md-12'>
                                <button type='button' onClick={() => navigate('/addCompteRendu')} className='btn btn-info'>Ajouter compte rendu</button>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: 10 }}>
                            <DataTable value={mcr} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className='custom-datatable'>
                                <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='titre' header="TITRE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                            </DataTable>
                        </div>
                    </div>
                </section>
            </section>:<Loading/>}
            <Footer />
        </section>
    );
}

export default ModelCompteRendu;