import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import CropperImage from './centers/Cropper';
import { ThemeContext } from '../context/ContextHeader';
import { useContext } from 'react';
import EditionMembre from './centers/EditionMembre';
import reactSessionApi from 'react-session-api';
function MenuCentre(props) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [position, setPosition] = useState('top');
  const usernameconnected = sessionStorage.getItem("nom");
  const membre_id = sessionStorage.getItem("membre_id");
  const user_id = sessionStorage.getItem("user_id");
  const {
    nbFolderAvisSend,
    nbFolderAvisReceive,
    dossiersEn,
    dossiersCorbeille,
    dossiersArchive,
    visibleModelSignature, setVisibleModelSignature,
    boolResultat, setBoolResult,
    boolCompte_expertise, setBoolCompte_expertise,
    boolNotifications, setBoolNotifications,
    boolStatistique, setBoolStatistique,
    boolMcr, setBoolMcr,
    boolArchives, setBoolArchives,
    boolCorbeille, setBoolCorbeille,
    visibleProfile,setVisibleProfile,
    avatar

  } = useContext(ThemeContext);

  useEffect(() => {
    if (location.pathname === "/resultat") {
      setBoolResult(true);
      setBoolCorbeille(false);
      setBoolCompte_expertise(false);
      setBoolNotifications(false);
      setBoolStatistique(false);
      setBoolMcr(false);
      setBoolCorbeille(false);
      setBoolArchives(false);
    } else if (location.pathname === "/compte_expertise") {
      setBoolCompte_expertise(true);
      setBoolResult(false);
      setBoolCorbeille(false);
      setBoolNotifications(false);
      setBoolStatistique(false);
      setBoolMcr(false);
      setBoolCorbeille(false);
      setBoolArchives(false);
    } else if (location.pathname === "/notifications") {
      setBoolNotifications(true);
      setBoolCompte_expertise(false);
      setBoolResult(false);
      setBoolCorbeille(false);
      setBoolStatistique(false);
      setBoolMcr(false);
      setBoolCorbeille(false);
      setBoolArchives(false);
    } else if (location.pathname === "/statistiques") {
      setBoolStatistique(true);
      setBoolCompte_expertise(false);
      setBoolResult(false);
      setBoolCorbeille(false);
      setBoolNotifications(false);
      setBoolMcr(false);
      setBoolCorbeille(false);
      setBoolArchives(false);
    } else if (location.pathname === "/model_compte_rendu") {
      setBoolMcr(true);
      setBoolCompte_expertise(false);
      setBoolResult(false);
      setBoolCorbeille(false);
      setBoolNotifications(false);
      setBoolStatistique(false);
      setBoolCorbeille(false);
      setBoolArchives(false);
    } else if (location.pathname === "/archives") {
      setBoolArchives(true);
      setBoolCompte_expertise(false);
      setBoolResult(false);
      setBoolCorbeille(false);
      setBoolNotifications(false);
      setBoolStatistique(false);
      setBoolMcr(false);
      setBoolCorbeille(false);
    } else if (location.pathname === "/corbeille") {
      setBoolCorbeille(true);
      setBoolCompte_expertise(false);
      setBoolResult(false);
      setBoolNotifications(false);
      setBoolStatistique(false);
      setBoolMcr(false);
      setBoolArchives(false);

    }

  }, [location])
  const newLocal = <center><button type='button' className='btn btn-info'>Profil</button></center>;
  return (
    <ul class="sidebar-menu" id="nav-accordion">
      <Dialog headerStyle={{ backgroundColor: "#4ECDC4" }} header="Veuillez joindre une signature numérique" visible={visibleModelSignature} position={position} style={{ width: '40vw' }} onHide={() => setVisibleModelSignature(false)} draggable={false} resizable={false}>
        <div className='container-fluid'>
          <div className='row' style={{marginTop:20}}>
            <CropperImage />
          </div>
        </div>
      </Dialog>
      <Dialog header="PROFIL" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visibleProfile} style={{ width: '50vw' }} onHide={() => { if (!visibleProfile) return; setVisibleProfile(false); }}>
        <EditionMembre id={membre_id} />
      </Dialog>
      <p class="centered">
        <a href="javascript:;" onClick={() => navigate('/')}><img src={avatar} class="img-round" width={80} height={80} className='img-circle' /></a>
      </p>
      <h5 className="centered"  title='Cliquez pour voir votre profile'>{props.username}</h5>
       <p style={{color:"red",fontWeight:'bold',cursor:"pointer"}} onClick={() => navigate('/profil')} className="centered">Mon profil</p>
      <hr style={{ width: "80%" }} />
      <li class="sub-menu" style={boolResultat == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/resultat')} style={boolResultat == true ? { color: 'white' } : {}}>
          <i class="fa fa-folder"></i>
          <span>Dossiers reçus&nbsp;&nbsp;<span class="badge bg-theme">{dossiersEn.length > 0 ? dossiersEn.length : 0}</span></span>
        </a>
      </li>
      <li class="sub-menu" style={boolCompte_expertise == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/compte_expertise')} style={boolCompte_expertise == true ? { color: 'white ' } : {}}>
          <img src="../../../img/diamond_26_whitepx.png" width="15" height="15" />&nbsp;
          <span>Télé-expertise[{nbFolderAvisSend}-{nbFolderAvisReceive}]&nbsp;&nbsp;<span class="badge bg-theme">{nbFolderAvisReceive + nbFolderAvisSend}</span></span>
        </a>
      </li>
      <li class="sub-menu" style={boolNotifications == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white ' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/notifications')} style={boolNotifications == true ? { color: 'white ' } : {}}>
          <i class="fa fa-bell-o"></i>
          <span>Notifications&nbsp;&nbsp;<span class="badge bg-theme"></span></span>
        </a>
      </li>
      <li class="sub-menu" style={boolStatistique == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white ' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/statistiques')} style={boolStatistique == true ? { color: 'white ' } : {}}>
          <i class="fa fa-bar-chart-o"></i>
          <span>Statistiques</span>
        </a>
      </li>
      <li class="sub-menu" style={boolMcr == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white ' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/model_compte_rendu')} style={boolMcr == true ? { color: 'white ' } : {}}>
          <i class="fa fa-clipboard"></i>
          <span>Modèle compte rendu </span>
        </a>
      </li>
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => setVisibleModelSignature(true)}>
          <i class="fa fa-text-width"></i>
          <span>Signature numérique</span>
        </a>
      </li>
      <li class="sub-menu" style={boolArchives == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/archives')} style={boolArchives == true ? { color: 'white' } : {}}>
          <i class="fa fa-cloud-download"></i>
          <span>Archives&nbsp;&nbsp;<span class="badge bg-theme"> {dossiersArchive?.length > 0 ? dossiersArchive?.length : 0}</span></span>
        </a>
      </li>
      <li class="sub-menu" >
        <a href="https://www.imaios.com/fr/imaios-dicom-viewer" target="_blank" >
          <i class="fa fa-external-link"></i>
          <span>Lecteur Dicom online</span>
        </a>
      </li>
      <li class="sub-menu" style={boolCorbeille == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/corbeille')} style={boolCorbeille == true ? { color: 'white' } : {}}>
          <i class="fa fa-bitbucket"></i>
          <span>Corbeille&nbsp;&nbsp;<span class="badge" style={{ backgroundColor: 'red' }}>{dossiersCorbeille.length > 0 ? dossiersCorbeille.length : 0}</span></span>
        </a>
      </li>
    </ul>
  );
}

export default MenuCentre;