import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import EditorApi from './Editor';
import { ThemeContext } from '../../context/ContextHeader';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Editor } from 'primereact/editor';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import Select from 'react-select'
import Micro from './Micro';
import { Dialog } from 'primereact/dialog';
import { ReactCrop } from 'react-image-crop';
import { title } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useNavigate } from 'react-router-dom';
import JoditEditor, { Jodit } from 'jodit-react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import reactSessionApi from 'react-session-api';

function AddMcr() {
    const { text, setText, datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [optionValue, setOptionValue] = useState("fr-FR");

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const NEW_LINE = '\n';
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(null);
    const [src_1, setSrc_1] = useState(null);
    const [crop_1, setCrop_1] = useState({ aspect: 16 / 9 });
    const [image_1, setImage_1] = useState(null);
    const [output_1, setOutput_1] = useState(null);
    const [src_2, setSrc_2] = useState(null);
    const [crop_2, setCrop_2] = useState({ aspect: 16 / 9 });
    const [image_2, setImage_2] = useState(null);
    const [output_2, setOutput_2] = useState(null);
    const [statusFont, setStatusFont] = useState(false);
    const [visible, setVisible] = useState(false);
    const [titreCompteRendu, setTitreCompteRendu] = useState("");
    const [observation, setObservation] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [fontSize, setFontSize] = useState(12);
    const [statusRecord, setStatusRecord] = useState(false);
    const [aleaNumberCr, setAleaNumberCr] = useState(0);
    const [aleaNumberCrSession, setAleaNumberCrSession] = useState(parseInt(sessionStorage.getItem('alea')));
    const [mcr, setMcr] = useState([]);
    const [messageError, setMessageError] = useState(false);
    const [visibleOne, setVisibleOne] = useState(false);
    const useRefTitre = useRef(null);
    const editorRef = useRef(null);
    const [content, setContent] = useState('');
    const [mcrId, setMcrId] = useState('');
    const [visibleMcr, setVisibleMcr] = useState(false);
    const [VisibleTech, setVisibleTech] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const [transcription, setTranscription] = useState('');
    const [isListening,setIsListening]=useState(false);
    let joditEditor = null;


    useEffect(() => {
        const handleTranscriptionUpdate = (event) => {
            setTranscription(event.detail);
            console.log("Transcription updated:", event.detail);
        };
        document.addEventListener('transcriptionUpdated', handleTranscriptionUpdate);
        return () => {
            document.removeEventListener('transcriptionUpdated', handleTranscriptionUpdate);
        };
    }, []);

    useEffect(() => {
        if (editorRef.current) {
            joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.focus();
            joditEditor.selection.insertHTML(transcription);
        }
    }, [transcription]);

    const RechercheMcr = async (e) => {
        try {
            const value = e.target.value;
            if (value !== "") {
                const response = await axios.post(URL_AXIOS + `list_mcr_rechercheByMember/${membre_id}/${value}`);
                if (response.data.status === 200) {
                    setMcr(response.data.mcr);
                }
            } else {
                list_mcr(membre_id);
            }

        } catch (Error) {

        }
    }

    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };
    const selectImage_1 = (file) => {
        setSrc_1(URL.createObjectURL(file));
    };
    const selectImage_2 = (file) => {
        setSrc_2(URL.createObjectURL(file));
    };



    function BtnListeningVoiceToText(e) {
        try {
            if (isListening) {
                window.stopListening();
                setIsListening(false);

            } else {
                window.startButton(e)
                setIsListening(true);
            }
            // isListening = false;
            // recognition.stop();

        } catch (error) {

        }
    }
    const config = useMemo(
        () => ({
            style: {
                height: '500px',
            },
            allowResizeX: false,
            allowResizeY: false,
            autofocus: false,
            toolbarAdaptive: false,
            editorClassName: 'editorComponent',
            // autofocus: true,
            // cursorAfterAutofocus: 'start', // 'end';
            // saveSelectionOnBlur: true,
            // hidePoweredByJodit: false,
            // zIndex: 0,
            readonly: false,
            // inline: false,
            //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
            toolbarButtonSize: 'middle',
            theme: 'default',
            // saveModeInCookie: true,
            // spellcheck: false,
            // editorCssClass: true,
            // triggerChangeEvent: false,
            width: 'auto',
            // direction: '',
            // enter: "br",
            // editHTMLDocumentMode: true,
            // cursorAfterAutofocus: "end",
            // saveSelectionOnBlur: true,
            // nl2brInPlainText: true,
            // sourceEditor: "area",
            language: 'auto',
            // debugLanguage: false,
            i18n: 'fr',
            tabIndex: -1,
            toolbar: true,
            useSplitMode: false,
            colors: {
                greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
                palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
                full: [
                    '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                    '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                    '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                    '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                    '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                    '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
                ]
            },

            colorPickerDefaultTab: 'background',
            removeButtons: [],
            disablePlugins: [],
            extraButtons: [],
            sizeLG: 900,
            sizeMD: 700,
            statusbar: false,
            // shadowRoot: null,
            //sizeSM: 400,
            //sizeSM: 400,
            speechRecognize: {
                lang: 'fr-FR',
                commands: {
                    'newline|enter': 'enter',
                    'delete|remove word|delete word': 'backspaceWordButton',
                    point: 'inserthtml::,',
                    underline: 'inserthtml::_',
                    hyphen: 'inserthtml::-',
                    space: 'inserthtml:: ',
                    question: 'inserthtml::?',
                    dot: 'inserthtml::.',
                    'quote|quotes|open quote': "inserthtml::'",
                    'header|header h1': 'formatblock::h1',
                    'select all': 'selectall'
                }
            },
            buttons: [
                'source', '|',
                'bold',
                'strikethrough',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'font',
                'fontsize',
                'brush',
                'paragraph', '|',
                'table',
                'link', '|',
                'align', 'undo', 'redo', '|',
                'hr',
                'eraser',
                'print',



            ],
            extraButtons: [
                {
                    name: 'customSpeechRecognition',
                    iconURL:isListening ? "../../miciconmuted.png" : "../../micoff2.png", // Use a microphone icon URL
                    exec: (e) => BtnListeningVoiceToText(e),
                    tooltip: 'Démarrer / Arrêter enregistrement',

                },
                isListening ? 'Enregistrement est en cours ...' : ''
            ]
            ,

            /*buttonsXS: [
                'bold',
                'image', '|',
                'paragraph', '|',
                'align', '|',
                'undo', 'redo', '|',
                'eraser',
                'dots'
            ],*/
            events:
            {

            },

            textIcons: false,
        }),
        [isListening]
    );



    const cropImageNow = () => {
        const imageL = document.getElementById("cropp");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput(base64Image);
    };
    const insertSymbol = (text) => {
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }
        setVisibleTech(false);
    };

    const insertMrc = (text) => {
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }
        setVisibleMcr(false);
    };
    const cropImageNow_1 = () => {
        const imageL = document.getElementById("cropp1");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_1.width;
        canvas.height = crop_1.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_1.width * pixelRatio;
        canvas.height = crop_1.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_1.x * scaleX,
            crop_1.y * scaleY,
            crop_1.width * scaleX,
            crop_1.height * scaleY,
            0,
            0,
            crop_1.width,
            crop_1.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_1(base64Image);
    };

    const cropImageNow_2 = () => {
        const imageL = document.getElementById("cropp2");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_2.width;
        canvas.height = crop_2.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_2.width * pixelRatio;
        canvas.height = crop_2.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_2.x * scaleX,
            crop_2.y * scaleY,
            crop_2.width * scaleX,
            crop_2.height * scaleY,
            0,
            0,
            crop_2.width,
            crop_2.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_2(base64Image);
    };

    const list_mcr = async (membre_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcrByMember/${membre_id}`);
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
            }
        } catch (Error) {

        }
    }

    const addMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;

            if (editorRef.current.value !== "" && titreCompteRendu != "") {

                if (mcrId !== "") {
                    const data = {
                        titre: titreCompteRendu,
                        observation: editorRef.current.value,
                        statut: '0',
                        id_membre: membre_id,
                        id_mcr: mcrId
                    }
                    const response = await axios.post(URL_AXIOS + 'updateMcr', data);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: 'Réussi !',
                            text: 'Le modèle de compte rendu a été enregistré avec succès !',
                            icon: 'success',

                        })
                        setTitreCompteRendu("");
                        setMcrId("");
                        setContent("")
                        list_mcr(membre_id);
                        setMessageError(false)
                    } else {
                        alert("Erreur savings")
                    }
                } else {
                    const data = {
                        titre: titreCompteRendu,
                        observation: editorRef.current.value,
                        statut: '0',
                        id_membre: membre_id,

                    }
                    const response = await axios.post(URL_AXIOS + 'addMcr', data);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: 'Réussi !',
                            text: 'Le modèle de compte rendu a été enregistré avec succès !',
                            icon: 'success',

                        })
                        setTitreCompteRendu("");
                        setContent("")
                        list_mcr(membre_id);
                        setMessageError(false);
                        navigate('/model_compte_rendu')
                    } else {
                        alert("Erreur savings")
                    }
                }


            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })
                useRefTitre.current.focus();
                setMessageError(true)
            }
        } catch (error) {
            alert("erreur 12" + error)
        }
    }

    const deleteMcr = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_mcr/${id}`);
            if (response.data.status === 200) {
                list_mcr(membre_id);
                setMcrId("");
            }
        } catch (error) {

        }
    }

    const deleteConfirmMrc = (id) => {
        setVisibleMcr(false)
        try {
            Swal.fire({
                title: "Suppression",
                text: "Êtes-vous sûr de vouloir supprimer ce compte rendu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Réussi !",
                        text: "Le modèle de compte rendu a été supprimé avec succès !",
                        icon: "success"
                    });
                    deleteMcr(id);
                } else {
                    setVisibleMcr(true)
                }
            });
        } catch (error) {

        }
    }

    const contentEditor = () => {
        return (
            <div>
                <div id="right_options_detail_mcr"></div>
                <div className='row'>
                    <div className=' card' style={{ height: 60 }}>
                        <div class="row" style={{ padding: 5, margin: 0, background: '#fff' }}>
                            <div className='col col-md-3'>

                            </div>
                            <div class="col-md-6 pr-1">
                                <input type="text" name="titre"
                                    value={titreCompteRendu}
                                    onChange={(e) => setTitreCompteRendu(e.target.value)}
                                    ref={useRefTitre}
                                    class="form-control" placeholder="Titre compte rendu " style={{ marginTop: 9, fontWeight: "bold" }} />
                            </div>
                            <div class="col-md-3 pr-1">
                                <input type="hidden" name="nom_pat" id="nom_pat" class="form-control" placeholder="Noms patient" data-toggle="tooltip" data-placement="bottom" title="Noms patient" autocomplete="nom_pat" style={{ borderBottom: 'none', borderLeft: '1px solid #ccc' }} />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row" style={{ marginTop: 10 }}>
                    {/*<!-- DEBUT 1ere Colonne button save & update -->*/}
                    <div class="col-md-1" style={{ height: '10vh', overflow: 'hidden' }} >

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table class="table">
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <button type="button" data-toggle="modal" data-target="#saveModal" className="btn btn-default save"
                                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                                            onClick={() => addMcr()}
                                                        >
                                                            <i class="fa fa-mobile " data-toggle="tooltip" data-placement="bottom" title="Enregistrer"></i>
                                                        </button>

                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*<!-- FIN 1ere Colonne button save & update -->*/}

                    {/*<!-- DEBUT 2eme Colonne editeur -->*/}
                    <div className="col-md-10 pr-1" style={{ height: '72vh', overflow: 'hidden' }}>

                        <div className="row">

                            <div className="col-md-12">

                                <div id="app_container">
                                <div id='mirror' ></div>
                                    {/* {transcript && <div style={{ backgroundColor: "grey", height: "auto", opacity: "0.9", width: "auto", zIndex: 2000, position: 'absolute' }}>{transcript}</div>} */}
                                    <div style={{ marginTop: 10 }}>
                                  
                                        <JoditEditor
                                            // value={editorContent}
                                            ref={editorRef}
                                            tabIndex={1}
                                            config={config}
                                            // onChange={(newContent) => setEditorContent(newContent)}
                                        />

                                    </div>
                                    {/*<!-- Modal Techniques -->*/}
                                    <Dialog header="Techniques" headerStyle={{ backgroundColor: "#4ECDC4", color: "white" }} visible={VisibleTech} style={{ width: '50vw' }} onHide={() => { if (!VisibleTech) return; setVisibleTech(false); }}>
                                        <div>
                                            <table id="commands_table">
                                                <tr>
                                                    <th id="cell_say">Scanner</th>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                    <td id="cell_sad">Acquisition spiralée sans injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans et avec injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                    <td id="cell_sad">Acquisition spiralée sans et avec injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                </tr>
                                                <tr>
                                                    <th id="cell_say">IRM</th>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.')}>
                                                    <td id="cell_sad">Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.</td>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.')}>
                                                    <td id="cell_sad">Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.</td>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.')}>
                                                    <td id="cell_sad">Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.</td>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('DP Fastat dans les trois plans, T1 sagittales')}>
                                                    <td id="cell_sad">DP Fastat dans les trois plans, T1 sagittales.</td>
                                                </tr>
                                                <tr title="Cliquez pour insérer" onClick={() => insertSymbol('T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO')}>
                                                    <td id="cell_sad">T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO.</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Dialog>


                                    {/*  <!-- Modal Model CR -->*/}
                                    <Dialog header="Modèles enregistrés" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visibleMcr} style={{ width: '50vw' }} onHide={() => { if (!visibleMcr) return; setVisibleMcr(false); }}>
                                        <div className='container-fluid' style={{ marginTop: 10 }}>
                                            <div className='row'>
                                                <input type='text' onChange={RechercheMcr} placeholder='Recherche...' className='form-control' />
                                                {mcr.map((val, index) => (
                                                    <div class="flex flex-column md:flex-row" key={index}>

                                                        <div class="flex align-items-center  h-4rem card font-bold m-2" onClick={() => insertMrc(val.observation)} style={{ width: "96%", cursor: "pointer" }}>

                                                            <h5 style={{ marginLeft: 10 }}> {val.titre}</h5>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center  h-4rem bg-default font-bold border-round m-2" style={{ width: "4%" }}>
                                                            <button className='btn btn-danger' onClick={() => deleteConfirmMrc(val.id)} style={{}}>
                                                                <i className='fa fa-remove' style={{ color: 'white' }}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))

                                                }
                                                {mcr.length <= 0 && <p>Pas de modèles enregistrés</p>}
                                            </div>

                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <!-- FIN 2eme Colonne editeur-- >
    
        < !--DEBUT 3eme Colonne button aperçu, model cr, (button declencheur de modal)-->*/}
                    <div class="col-md-1" style={{ height: '28vh', overflow: 'hidden' }} id='right_options_detail_mcr'>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table className="table" style={{ width: '100%' }}>
                                            <tbody >

                                                <tr>
                                                    <td>

                                                        <button type="button" onClick={() => setVisibleMcr(true)} className="btn btn-default model" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-book files_paper" data-toggle="tooltip" data-placement="bottom" title="Charger un modèle de compte rendu"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                {/* <tr id="cvr">
                                                    <td >
                                                        <center>
                                                            <button type="button" data-toggle="modal" data-target="#cvrModal" class="btn btn-default options" style={{ width: '100%', border: 'none' }} >
                                                                <i class="fa fa-gear" data-toggle="tooltip" data-placement="bottom" title="Options"></i>
                                                            </button>
                                                        </center>

                                                    </td>
                                                </tr> */}
                                                <tr id="tech">
                                                    <td>
                                                        <button type="button" onClick={() => setVisibleTech(true)} class="btn btn-default" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-tags shopping_tag-content" data-toggle="tooltip" data-placement="bottom" title="Techniques"></i>
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div >
        )
    }
    useEffect(() => {
        setDisableAvis(true);
        list_mcr(membre_id);
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
        window.runOnload();
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">

                    {contentEditor()}

                </section>
            </section>
            <Footer />
        </div>
    );
}

export default AddMcr;