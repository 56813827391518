import React, { useContext, useEffect, useRef } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react';
import { Country, State, City } from 'country-state-city';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS, URL_SIGNATURE } from '../../URL/URL';

import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Divider } from 'primereact/divider';
import Loading from './Loading';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
function Profil() {

    const { id } = useParams();
    const [matricule, setMatricule] = useState("");
    const [imageName, setImageName] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [sexe, setSexe] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [qualification, setQualification] = useState(null);
    const [specialite, setSpecialite] = useState(null);
    const [experience, setExperience] = useState(null);
    const [pays, setPays] = useState(null);
    const [ville, setVille] = useState(null);
    const [biographie, setBiographie] = useState("");
    const [image, setImage] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [exp, setExp] = useState("");
    const [signature, setSignature] = useState(null);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const navigate = useNavigate();
    const [phone, setPhone] = useState("242");
    const [countries, setCountries] = useState([]);
    const [region, setRegion] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [departement, setDepartement] = useState(null);
    const fileInputRef = useRef(null);
    const [message_user_id, setMessage_user_id] = useState("");
    const [message_matricule, setMessage_matricule] = useState("");
    const [message_nom, setMessage_nom] = useState("");
    const [message_prenom, setMessage_prenom] = useState("");
    const [message_sexe, setMessage_sexe] = useState("");
    const [message_mail, setMessage_mail] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_qualification, setMessage_qualification] = useState("");
    const [message_specialite, setMessage_specialite] = useState("");
    const [message_experience, setMessage_experience] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [message_biographie, setMessage_biographie] = useState("");
    const [message_signature, setMessage_signature] = useState("");
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const useRefImage = useRef(null);
    const [avatarSetImage, setAvatarSetImage] = useState("");
    const [avatarImage, setAvatarImage] = useState(null);
    const [cv, setCv] = useState(null);
    const [cvget, setCvget] = useState(null);
    const [countryData, setCountryData] = useState("");
    const [departeData, setDeparteData] = useState("");
    const [loading, setLoading] = useState(true);
    const [visibleProgressBar, setVisibleProgressBar] = useState(false);
    const [valueProgressBar, setValueProgressBar] = useState(0);
    const [qualificationGet, setQualificationGet] = useState("");
    const [specialiteGet, setspecialiteGet] = useState("");
    const [experienceGet, setexperienceGet] = useState("");
    const selectedCv = (e) => {
        setCv(e.target.files[0])
    }
    const HandleAvatar = () => {
        useRefImage.current.click();
    };
    const handleFileChangeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        setAvatarImage(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarSetImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    // const [experiences, setExperiences] = useState([
    //     {
    //         label: "1 - 2 an(s)",
    //         value: "1 - 2 an(s)"
    //     },
    //     {
    //         label: "2 - 5 an(s)",
    //         value: "2 - 5 an(s)"
    //     },
    //     {
    //         label: "5 - 10 an(s)",
    //         value: "5 - 10 an(s)"
    //     },
    //     {
    //         label: "10 an(s) ou plus",
    //         value: "10 an(s) ou plus"
    //     }
    // ])
    const qualifications = [
        { name: "Médecin", value: "Médecin" },
        { name: "Professeur agrégé", value: "Professeur agrégé" }
    ]
    const specialites = [
        { name: "Radiologue", value: "Radiologue" }
    ]
    const experiences = [
        {
            name: "1-2 an(s)",
            value: "1-2 an(s)"
        },
        {
            name: "2-5 an(s)",
            value: "2-5 an(s)"
        },
        {
            name: "5-10 an(s)",
            value: "5-10 an(s)"
        },
        {
            name: "10 an(s) ou plus",
            value: "10 an(s) ou plus"
        }
    ]


    const handleClick = () => {
        fileInputRef.current.click();
    };

    const EmailValidation = (email) => {

        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const validationFields = () => {

        if (user_id == "") {

            setMessage_user_id("Veuillez saisir utilisateur");

        }

        if (nom == "") {

            setMessage_nom("Veuillez saisir le nom");

        }

        if (email == "") {

            setMessage_mail("Veuillez saisir le mail");

        }

        if (telephone == "") {

            setMessage_telephone("Veuillez saisir le n° de téléphone");

        }

        if (qualification == "") {

            setMessage_qualification("Veuillez saisir la qualification");

        }
        if (specialite == "") {

            setMessage_specialite("Veuillez saisir la spécialité");

        }

        if (experience == "") {

            setMessage_experience("Veuillez saisir expérience");

        }

        if (pays == "") {

            setMessage_pays("Veuillez sélectionner le pays");

        }
        if (departement == "") {

            setMessage_ville("Veuillez sélectionner la ville");

        }
        if (sexe == "") {

            setMessage_sexe("Veuillez sélectionner le sexe !");

        }

    }

    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }
    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';
        link.href = value;
        link.target = "_blank";
        link.click();
    };
    const handleInputNom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNom(value)
            setMessage_nom("");
        } else {
            setNom("")
        }
    }
    const handleInputPrenom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPrenom(value)
            setMessage_prenom("");
        } else {
            setPrenom("")
        }
    }
    const handleInputSexe = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSexe(value)
            setMessage_sexe("");
        } else {
            setSexe("")
        }
    }
    const handleInputTelephone = (value) => {
        //const value = event.target.value;
        // Use a regular expression to allow only numbers (including negative numbers)
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            // If the input value contains only numbers or negative numbers,
            // update the telephone state with the value.
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            setTelephone("");
            setMessage_telephone("Le n° de téléphone est invalid !");
        }
    }

    const handleInputQualification = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setQualification(value)
            setMessage_qualification("");
        } else {
            setQualification("")
        }
    }

    const handleInputSpecialite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSpecialite(value)
            setMessage_specialite("");
        } else {
            setSpecialite("")
        }
    }
    const handleInputExperience = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setExperience(value)
            setMessage_experience("");
        } else {
            setExperience("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSignature(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const updateMembre = async () => {
        try {
            validationFields();
            const formData = new FormData();
            formData.append('user_id', user_id);
            formData.append('matricule', matricule || "");
            formData.append('nom', nom);
            formData.append('prenom', prenom || "");
            formData.append('sexe', sexe);
            formData.append('email', email);
            formData.append('telephone', telephone);
            formData.append('qualification', qualification);
            formData.append('specialite', specialite);
            formData.append('experience', experience);
            formData.append('pays', pays);
            formData.append('ville', departement);
            formData.append('biographie', biographie || "");
            formData.append('signature', signature);
            formData.append('photo', avatarImage);
            formData.append('cv', cv);

            if (nom !== "" && sexe !== "" && email !== "" && telephone !== "" && qualification !== "" && specialite !== "" && experience !== "" && pays !== "" && departement !== "") {
                if (EmailValidation(email)) {
                    const response = await axios.post(URL_AXIOS + `update_membre/${membre_id}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: ({ loaded, total }) => {
                            const value_loaded = Math.floor((loaded / total) * 100);
                            setValueProgressBar(value_loaded);
                            setVisibleProgressBar(true);
                            if (loaded === total) setVisibleProgressBar(false);


                        }
                    });
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: 'Réussi !',
                            text: 'Les informations du membre ont été mis à jour avec succès !',
                            icon: 'success',

                        })
                        
                    } else {
                        console.log(response.data.message)
                        setVisibleProgressBar(false);
                        Swal.fire({
                            title: 'Erreur !',
                            text: "La modification du membre a échouée !",
                            icon: 'error',

                        })
                    }

                }
            }

        } catch (error) {
           
        }

    };



    const handleCountryChange = (e) => {
        const name_get = e.value;
        if (name_get !== "") {
            setPays(name_get);
            setMessage_mail("");
        } else {
            setPays("")
        }

    };

    const onSelectVille = (pays) => {
        if (pays) {
            const filtered = countries.filter((item) => item.name === pays.name);
            setFilteredData(filtered);

            filtered.forEach((filter) => {
                const states = State.getStatesOfCountry(filter.isoCode);
                // const phonecode = filter.phonecode;
                setRegion(states);
                // setCode(phonecode)
            });
        }

    }

    const onSelectDepart = (e) => {
        if (pays) {
            const filtered = countries.filter((item) => item.name === pays.name);
            // setFilteredData(filtered);

            filtered.forEach((filter) => {
                const states = State.getStatesOfCountry(filter.isoCode);
                // const phonecode = filter.phonecode;
                setRegion(states);
                // setCode(phonecode)
            });
        }

    }
    const liste_membres_by_id = async (id) => {
        try {
            const res = await axios.get(URL_AXIOS + `get_membre_by_id/${id}`);
            if (res.data.status == 200) {
                //setUser_id(res.data.user_id);
                setMatricule(res.data.matricule);
                setNom(res.data.membres.nom);
                setPrenom(res.data.membres.prenom);
                setSexe(res.data.membres.sexe);
                setEmail(res.data.membres.email);
                setTelephone(res.data.membres.telephone);
                setQualificationGet(res.data.membres.qualification)
                setspecialiteGet(res.data.membres.specialite);
                setexperienceGet(res.data.membres.experience);
                const pathCv = URL_SIGNATURE + res.data.membres.cheminfichiers + "/" + res.data.membres.cv;
                res.data.membres.cv ? setCvget(pathCv) : setCvget("");
                setCountryData(res.data.membres.pays);
                setDeparteData(res.data.membres.ville);
                setVille(res.data.membres.ville);
                setBiographie(res.data.membres.biographie);
                const path = URL_SIGNATURE + res.data.membres.cheminfichiers + "/" + res.data.membres.signature;
                (res.data.membres.signature !== null && res.data.membres.signature !== "N" && res.data.membres.signature !== "")
                    ? setImage(path)
                    : setImage("https://img.freepik.com/vecteurs-premium/modele-vectoriel-pour-autocollant-banniere-autocollant-affiche-typographie-etc_98908-478.jpg");

                const path_photo = URL_SIGNATURE + res.data.membres.cheminfichiers + "/" + res.data.membres.image;
                (res.data.membres.image !== null && res.data.membres.image !== "") ? setAvatarSetImage(path_photo) : setAvatarSetImage("https://www.shutterstock.com/image-vector/camera-add-icon-600nw-1054194038.jpg")
                setLoading(false);

            } else {
                alert("data")
            }
        } catch (Error) {

        }

    }
    // Fonction pour supprimer les accents et mettre en minuscules
    const normalizeString = (str) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
    };
    const handleCountryChangeNew = (e) => {
        setPays(e.value)
        setCountryData(e.value);
        // setDeparteData(""); // Réinitialiser le département sélectionné lorsque le pays change
    };
    const handleDepartementChange = (e) => {
        setDepartement(e.value);
        // setDeparteData(e.value ? e.value.name : ""); // Mettre à jour la valeur de départ
    };
    useEffect(() => {
        liste_membres_by_id(membre_id);
    }, [membre_id])
    useEffect(() => {

        try {
            if (qualificationGet && qualificationGet.trim()) {
                const normalizedQualificationGet = normalizeString(qualificationGet.trim());
                const filtered = qualifications.filter((item) => normalizeString(item.name) === normalizedQualificationGet);
                const getDataCountry = filtered.length ? filtered[0] : {};
                setQualification(getDataCountry.name);
            } else {

            }
        } catch (error) {

        }
    }, [qualificationGet]);
    useEffect(() => {

        try {
            if (specialiteGet && specialiteGet.trim()) {
                const normalizedspecialiteGet = normalizeString(specialiteGet.trim());
                const filtered = specialites.filter((item) => normalizeString(item.name) === normalizedspecialiteGet);
                const getDataCountry = filtered.length ? filtered[0] : {};
                setSpecialite(getDataCountry.name);
            } else {

            }
        } catch (error) {

        }
    }, [specialiteGet]);

    useEffect(() => {
        try {
            if (experienceGet && experienceGet.trim()) {
                const normalizedExperienceGet = normalizeString(experienceGet.trim());
                const filtered = experiences.filter((item) => normalizeString(item.name.trim()) === normalizedExperienceGet);
                const getDataCountry = filtered.length ? filtered[0] : {};
                setExperience(getDataCountry.name || "");
            }
        } catch (error) {

        }

    }, [experienceGet]);

    useEffect(() => {
        if (countryData && departeData) {
            const filtered = countries.filter((item) => item.name === countryData);
            const getDataCountry = filtered.length ? filtered[0] : {};
            setPays(getDataCountry.name);
            onSelectVille(getDataCountry)
            if (getDataCountry.isoCode) {
                try {
                    const states = State.getStatesOfCountry(getDataCountry.isoCode);
                    const specificState = states.filter((state) => state.name === departeData);
                    const getDataState = specificState.length ? specificState[0] : {};
                    setDepartement(getDataState.name);
                } catch (error) {
                    console.error("Error fetching states:", error);
                }
            }

        } else {

        }
    }, [countryData, departeData]);

    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }

    }, []);
    return (
        <section id="container">
            <Header />
            <Dialog header="Envoi en cours..." visible={visibleProgressBar} draggable={false} modal={true} style={{ width: '50vw' }} closable={false} onHide={() => setVisibleProgressBar(false)}>
                <ProgressBar value={valueProgressBar}></ProgressBar>
            </Dialog>
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section className="wrapper card" style={{ height: "auto" }}>
                    <div className="row" style={{ padding: 10 }}>
                        <h4>Informations d'identification</h4>
                        <Divider />
                        <div className='col col-md-10'>
                            <div className='row'>
                                <div className='col col-md-10'>
                                    <input type='text' className='form-control' placeholder='Nom(s) *'
                                        value={nom} onChange={handleInputNom}
                                        style={{ fontWeight: 600 }}
                                    />
                                    {message_nom !== "" ? <label style={{ color: 'red' }}>{message_nom}</label> : ""}

                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-10'>
                                    <input type='text' className='form-control' placeholder='Prenom(s) '
                                        value={prenom} onChange={handleInputPrenom}
                                        style={{ fontWeight: 600 }}
                                    />
                                
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-3'>
                                    <select className='form-control' value={sexe} onChange={handleInputSexe}
                                        style={{ fontWeight: 600 }}>
                                        <option value={""}>Sexe *</option>
                                        <option value='M'>M</option>
                                        <option value='F'>F</option>
                                    </select>
                                    {message_sexe !== "" ? <label style={{ color: 'red' }}>{message_sexe}</label> : ""}

                                </div>

                            </div>

                        </div>
                        <div className='col col-md-2'>
                            <div style={{ width: "90%", height: '150px', border: "1px solid grey", display: "flex", justifyContent: "center" }}>
                                <img src={avatarSetImage}
                                    style={{ width: "100%", marginLeft: 0, height: "100%", cursor: "pointer" }}
                                    onClick={() => HandleAvatar()}
                                    title={'Veuillez cliquer pour sélectionner la photo'}
                                />
                            </div>

                            <input type='file'
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleFileChangeAvatar}
                                ref={useRefImage}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ padding: 10 }}>
                        <h4>Informations de contacts</h4>
                        <Divider />
                        <div className='col col-md-10'>
                            <div className='col col-md-6'>
                                <input type='text' className='form-control' placeholder='E-mail *'
                                    value={email} onChange={handleInputEmail} style={{ fontWeight: 600 }}
                                />
                                {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}

                            </div>
                            <div className='col col-md-6'>

                                <PhoneInput
                                    inputStyle={{ fontWeight: 600 }}
                                    country={'cg'}
                                    enableSearch={true}
                                    value={telephone}
                                    onChange={(value) => handleInputTelephone(value)}
                                    placeholder="Code * "
                                    disableCountryCode={true}
                                    inputProps={{
                                        required: true,

                                    }}

                                />

                                {message_telephone !== "" ? <label style={{ color: 'red' }}>{message_telephone}</label> : ""}

                            </div>
                        </div>

                        <div className='col col-md-2'>

                        </div>
                    </div>
                    <div className='row' style={{ padding: 10 }}>
                        <h4>Informations professionnelles</h4>
                        <Divider />
                        <div className='col col-md-10'>
                            <div className='row'>
                                <div className='col col-md-4'>

                                    <Dropdown
                                        value={qualification}
                                        name='qualification'
                                        onChange={(e) => setQualification(e.value)}
                                        options={qualifications}
                                        optionLabel="name"
                                        optionValue='name'
                                        placeholder="Qualification *"
                                        highlightOnSelect={true}
                                        className="w-full "
                                        emptyMessage="Aucune qualification disponible"

                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown
                                        value={specialite}
                                        name='specialite'
                                        onChange={(e) => setSpecialite(e.value)}
                                        options={specialites}
                                        optionLabel="name"
                                        optionValue='value'
                                        placeholder="Spécialité *"
                                        highlightOnSelect={true}
                                        className="w-full "
                                        emptyMessage="Aucune spécialité disponible"

                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown
                                        value={experience}
                                        name='experience'
                                        onChange={(e) => setExperience(e.value)}
                                        options={experiences}
                                        optionLabel="name"
                                        optionValue='value'
                                        placeholder="Expérience *"
                                        highlightOnSelect={true}
                                        className="w-full"
                                        emptyMessage="Aucune expérience disponible"

                                    />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-5' style={{ display: "flex", marginTop: 20 }}>
                                    <h6 style={{ marginTop: 10 }}>Curriculum vitae :</h6>
                                    <input type="file" accept=".pdf,application/pdf" onChange={(e) => selectedCv(e)} id="fileInput" style={{ marginLeft: 5, marginTop: 5 }} class="file-input" />
                                    {cvget && <i className='fa fa-download' onClick={(e) => handleDownload(e, cvget)} title='Télécharger votre cv' style={{ fontSize: 20, float: 'right', cursor: "pointer", marginTop: 5 }}></i>}

                                </div>
                            </div>
                        </div>
                        <div className='col col-md-2'>
                            <div style={{ width: "90%", height: '150px', border: "1px solid grey", display: "flex", justifyContent: "center" }}>

                                <img onClick={handleClick} title='Veuillez cliquer pour insérer la signature' style={{ width: '100%', height: '100%', cursor: 'pointer' }} src={image} />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ padding: 10 }}>
                        <h4>Informations de localisation</h4>
                        <Divider />
                        <div className='col col-md-10'>
                            <div className='row'>
                                <div className='col col-md-6'>

                                    <Dropdown
                                        value={pays}
                                        name='pays'
                                        onChange={(e) => handleCountryChangeNew(e)}
                                        options={countries}
                                        optionLabel="name"
                                        optionValue='name'
                                        placeholder="Pays *"
                                        highlightOnSelect={true}
                                        className="w-full"
                                        filter
                                        emptyMessage="Aucun pays disponible"

                                    />
                                    {message_pays !== "" ? <label style={{ color: 'red' }}>{message_pays}</label> : ""}
                                </div>
                                <div className='col col-md-6'>
                                    <Dropdown
                                        value={departement}
                                        name='pays'
                                        onChange={(e) => handleDepartementChange(e)}
                                        options={region}
                                        optionLabel="name"
                                        optionValue='name'
                                        placeholder="Ville *"
                                        className="w-full"
                                        filter
                                        highlightOnSelect={true}
                                        // style={{width:"90%"}}
                                        onSelect={(e) => onSelectDepart(e)}
                                        // disabled={examen_2_bool}
                                        emptyMessage="Aucune ville disponible"

                                    />
                                    {message_departement !== "" ? <label style={{ color: 'red' }}>{message_departement}</label> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ padding: 10 }}>
                        <h4>Biographie</h4>
                        <Divider />
                        <div className='col col-md-12'>
                            <textarea rows={10} className='form-control' placeholder='Ecrivez ici...' value={biographie} onChange={(e) => setBiographie(e.target.value)}>

                            </textarea>
                        </div>
                    </div>
                    <div className='row' style={{ padding: 10 }}>

                        <Divider />
                        <div className='col col-md-5'>

                        </div>
                        <div className='col col-md-2'>
                            <button class="btn btn-primary form-control" type="button"
                                onClick={() => updateMembre()}

                            >Mettre à jour</button>
                        </div>
                    </div>
                </section>

            </section>}
            <Footer />
        </section>
    );
}

export default Profil;