import axios from 'axios';
import { useContext, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { URL_AXIOS } from '../../URL/URL';
import { ThemeContext } from '../../context/ContextHeader';
import  Swal  from 'sweetalert2/dist/sweetalert2';


function CropperImage() {
    const {visibleModelSignature, setVisibleModelSignature}=useContext(ThemeContext)
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(null);
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };

    const [imageUrl, setImageUrl] = useState('');

    const handleImageChange = async(e) => {
      // Assuming you are getting the image URL from some user input or API response
      const newImageUrl = e.target.value;
      const blob = await fetch(newImageUrl).then((res) => res.blob());
      const imageFile = new File([blob], `image.jpg`, { type: 'image/jpeg' });
      // Update the state to change the image source
      setImageUrl(imageFile);
    };
    const cropImageNow = () => {
        const imageL = document.getElementById("cropp");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput(base64Image);
    };
    const AddHeaderDoc = async (id) => {
        try {
            // Check if the 'output' variable is not null
            if (output !== null) {
                // Fetch the image data from the 'output' URL
                const blob = await fetch(output).then((res) => res.blob());
    
                // Create a File object from the image data with a specified filename and type
                const imageFile = new File([blob], `image.jpg`, { type: 'image/jpeg' });
    
                // Send a POST request to the server using Axios
                const response = await axios.post(URL_AXIOS + `addSignature/${id}`, { file: imageFile },{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                // Check the response status and show an alert message
                if (response.data.status === 200) {
                    setVisibleModelSignature(false);
                    Swal.fire({
                        title: 'Réussi',
                        text: 'La signature a été mise à jour avec succès !',
                        icon: 'success',

                    })
                }else{
                    alert("Erreur 500");
                }
            } else {
                // Show an alert if 'output' is null
                alert("Veuillez cropper l'image");
            }
        } catch (error) {
            // Handle errors and show an alert message
            alert("Erreur " + error.message);
        }
    };
    
    return (
        <div className='container-fluid'>
            <div className='row'>
                
              
                <div className='col col-md-12'>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            selectImage(e.target.files[0]);
                        }}
                        className='form-control'
                    /><br />
                    <button type='button' onClick={() => AddHeaderDoc(membre_id)} className='btn btn-success form-control'>Enregistrer</button>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col col-md-6'>
                    <div style={{ width: "100%", height: "30%" }}>
                        {src && (
                            <div>
                                <ReactCrop onComplete={cropImageNow}
                                    crop={crop} onChange={setCrop} >
                                    <img src={src} id='cropp' style={{ width: "100%", height: "30%" }} />
                                </ReactCrop>

                            </div>
                        )}
                    </div>
                </div>
                <div className='col col-md-6'>
                    <div style={{ width: "100%", height: "50%" }}>
                        {output && <img src={output} style={{ width: "100%", height: "50%" }} />}
                    </div>
                </div>
            </div>


        </div>
    );
}

export default CropperImage;
