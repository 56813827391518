import { Panel } from 'primereact/panel'
import React, { useEffect, useRef, useState } from 'react'
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { Country, State, City } from 'country-state-city';
import { ProgressBar } from 'primereact/progressbar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Dialog } from 'primereact/dialog';
function MembreInfoAdd() {
    const stepperRef = useRef(null);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const emailSession = sessionStorage.getItem("email");
    const [matricule, setMatricule] = useState("");
    const [imageName, setImageName] = useState("");
    const [nom, setNom] = useState(usernameconnected);
    const [prenom, setPrenom] = useState("");
    const [sexe, setSexe] = useState("");
    const [email, setEmail] = useState(emailSession);
    const [telephone, setTelephone] = useState("");
    const [qualification, setQualification] = useState("");
    const [specialite, setSpecialite] = useState("");
    const [experience, setExperience] = useState("");
    const [pays, setPays] = useState('');
    const [ville, setVille] = useState("");
    const [biographie, setBiographie] = useState("");
    const [image, setImage] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [exp, setExp] = useState("");
    const [signature, setSignature] = useState(null);
    const [avatarImage, setAvatarImage] = useState(null);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const [phone, setPhone] = useState("242");
    const [countries, setCountries] = useState([]);
    const [region, setRegion] = React.useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [departement, setDepartement] = useState('');
    const fileInputRef = useRef(null);
    const [message_user_id, setMessage_user_id] = useState("");
    const [message_matricule, setMessage_matricule] = useState("");
    const [message_nom, setMessage_nom] = useState("");
    const [message_prenom, setMessage_prenom] = useState("");
    const [message_sexe, setMessage_sexe] = useState("");
    const [message_mail, setMessage_mail] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_qualification, setMessage_qualification] = useState("");
    const [message_specialite, setMessage_specialite] = useState("");
    const [message_experience, setMessage_experience] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [message_biographie, setMessage_biographie] = useState("");
    const [message_signature, setMessage_signature] = useState("");
    const [loading, setLoading] = useState(true);
    const [cv, setCv] = useState(null);
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [LoadingState, setLoadingState] = useState(false);
    const useRefImage = useRef(null);
    const [avatarSetImage, setAvatarSetImage] = useState("");
    const [oldPathFiles, setOldPathFiles] = useState("");
    const [boolUnblockBtn, setBoolUnblockBtn] = useState(true);
    const [visibleProgressBar, setVisibleProgressBar] = useState(false);
    const [valueProgressBar, setValueProgressBar] = useState(0);
    const [experiences, setExperiences] = useState([
        {
            label: "1-2 an(s)",
            value: "1-2 an(s)"
        },
        {
            label: "2-5 an(s)",
            value: "2-5 an(s)"
        },
        {
            label: "5-10 an(s)",
            value: "5-10 an(s)"
        },
        {
            label: "10 an(s) ou plus",
            value: "10 an(s) ou plus"
        }
    ])

    // const experiences = [
    //     {
    //         label: "1-2 an(s)",
    //         value: "1-2 an(s)"
    //     },
    //     {
    //         label: "2-5 an(s)",
    //         value: "2-5 an(s)"
    //     },
    //     {
    //         label: "5-10 an(s)",
    //         value: "5-10 an(s)"
    //     },
    //     {
    //         label: "10 an(s) ou plus",
    //         value: "10 an(s) ou plus"
    //     }
    // ]


    const handleClick = () => {
        fileInputRef.current.click();
    };

    const HandleAvatar = () => {
        useRefImage.current.click();
    };
    const EmailValidation = (email) => {

        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const validationFields = () => {

        if (user_id == "") {

            setMessage_user_id("Veuillez saisir utilisateur");

        }

        if (nom == "") {

            setMessage_nom("Veuillez saisir le nom");

        }

        if (prenom == "") {

            setMessage_prenom("Veuillez saisir le prenom");

        }

        if (email == "") {

            setMessage_mail("Veuillez saisir le mail");

        }

        if (telephone == "") {

            setMessage_telephone("Veuillez saisir le n° de téléphone");

        }

        if (qualification == "") {

            setMessage_qualification("Veuillez saisir la qualification");

        }
        if (specialite == "") {

            setMessage_specialite("Veuillez saisir la spécialité");

        }

        if (experience == "") {

            setMessage_experience("Veuillez saisir expérience");

        }

        if (pays == "") {

            setMessage_pays("Veuillez sélectionner le pays");

        }
        if (departement == "") {

            setMessage_ville("Veuillez sélectionner la ville");

        }
        if (sexe == "") {

            setMessage_sexe("Veuillez sélectionner le sexe !");

        }

    }

    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }

    const handleInputNom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNom(value)
            setMessage_nom("");
        } else {
            setNom("")
        }
    }
    const handleInputPrenom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPrenom(value)
            setMessage_prenom("");
        } else {
            setPrenom("")
        }
    }
    const handleInputSexe = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSexe(value)
            setMessage_sexe("");
        } else {
            setSexe("")
        }
    }
    const handleInputTelephone = (value) => {
        // //const value = event.target.value;
        // // Use a regular expression to allow only numbers (including negative numbers)
        // const regex = /^-?\d*$/;

        // if (regex.test(value)) {
        //     // If the input value contains only numbers or negative numbers,
        //     // update the telephone state with the value.

        if (value !== "") {
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            setTelephone("");
            setMessage_telephone("Le n° de téléphone est vide !");
        }
        // } else {
        //     setTelephone("");
        //     setMessage_telephone("Le n° de téléphone est invalid !");
        // }
    }

    const handleInputQualification = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setQualification(value)
            setMessage_qualification("");
        } else {
            setQualification("")
        }
    }

    const handleInputSpecialite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSpecialite(value)
            setMessage_specialite("");
        } else {
            setSpecialite("")
        }
    }
    const handleInputExperience = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setExperience(value)
            setMessage_experience("");
        } else {
            setExperience("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSignature(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleFileChangeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        setAvatarImage(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarSetImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const selectedCv = (e) => {
        setCv(e.target.files[0])
    }
    const addMembre = async () => {
        try {
            validationFields();
            const formData = new FormData();
    
            // const imageFile = new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });
            // const data_membre = {
            //     user_id: user_id,
            //     matricule: matricule,
            //     nom: nom,
            //     prenom: prenom,
            //     sexe: sexe,
            //     email: email,
            //     telephone: telephone,
            //     qualification: qualification,
            //     specialite: specialite,
            //     experience: experience,
            //     pays: pays,
            //     ville: departement,
            //     biographie: biographie,
            //     signature: signature,
            //     photo: avatarImage,
            //     cv: cv
            // }
    
            formData.append('user_id', user_id);
            formData.append('matricule', "");
            formData.append('nom', nom);
            formData.append('prenom', (prenom!=="" && prenom!==null) ? prenom: "" );
            formData.append('sexe', sexe);
            formData.append('email', email);
            formData.append('telephone', telephone);
            formData.append('qualification', qualification);
            formData.append('specialite', specialite);
            formData.append('experience', experience);
            formData.append('pays', pays);
            formData.append('ville', departement);
            formData.append('biographie', biographie);
            formData.append('signature', signature);
            formData.append('photo', avatarImage);
            formData.append('cv', cv);
            // formData.append('oldPathFiles', oldPathFiles);
    
            if (nom !== ""  && sexe !== "" && email !== "" && telephone !== "" && qualification !== "" && specialite !== "" && experience !== "" && pays !== "" && departement !== "") {
                if (EmailValidation(email)) {
                    const response = await axios.post(URL_AXIOS + 'addMembre', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: ({ loaded, total }) => {
    
                            const value_loaded = Math.floor((loaded / total) * 100);
                            setValueProgressBar(value_loaded);
                            setVisibleProgressBar(true);
                            if (loaded === total) setVisibleProgressBar(false);
    
    
                        }
                    });
                    if (response.data.status === 200) {
    
                        Swal.fire({
                            title: "Réussi !",
                            text: "Le membre a été enregistré avec succès !",
                            icon: 'success'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/')
                            }
                        });
                        setBoolUnblockBtn(true);
                        setNom("");
                        setPays("");
                        setPrenom("");
                        setSexe("");
                        setEmail("");
                        setTelephone("");
                        setQualification("");
                        setSpecialite("");
                        setExperience("");
                        setDepartement("");
                        setCv("");
                        setAvatarImage("");
                    } else {
                        setVisibleProgressBar(false);
                    }
    
                }
    
            } else {
    
            }
        } catch (error) {
            setVisibleProgressBar(false);   
        }
      

    };



    const handleCountryChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setPays(name_get);
            setMessage_mail("");
        } else {
            setPays("")
        }
        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            const phonecode = filter.phonecode;
            setRegion(states);
            // setCode(phonecode)
        });
    };


    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';
        link.href = value;
        link.target = "_blank";
        link.click();
    };
    useEffect(() => {
        if (nom !== "" && sexe !== "" && email !== "" && telephone !== "" && qualification !== "" && specialite !== "" && experience !== "" && pays !== "" && departement !== "") {
            setBoolUnblockBtn(false);
        } else {
            setBoolUnblockBtn(true);
        }

    }, [nom, sexe, email, telephone, qualification, specialite, experience, pays, departement])
    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();
        // if (usernameconnected === null || usernameconnected === "" || usernameconnected == undefined) {
        //     navigate('/')
        // }

    }, []);
    return (
        <div className='' style={{ backgroundImage: 'url(../../../img/body-bg.png)', backgroundSize: 'cover', height: '100%', width: '100%', position: 'absolute' }}>
            <Dialog header="Enregistrement est en cours..." visible={visibleProgressBar} draggable={false} modal={true} style={{ width: '50vw' }} closable={false} onHide={() => setVisibleProgressBar(false)}>
                <ProgressBar value={valueProgressBar}></ProgressBar>
            </Dialog>
            <div className='container'>
                <div className='row'>
                    <div className='col col-md-1'>

                    </div>
                    <div className='col col-md-10'>

                        <div className="card" style={{ height: "500px", marginTop: 20 }}>
                            <h3 style={{ textTransform: 'uppercase', padding: 15 }}>Complétez vos informations membres</h3>
                            <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }} orientation="vertical">
                                <StepperPanel header="Informations d'identification">
                                    <div className="row">

                                        <div className="col col-md-8">
                                            <div className='row' style={{ marginLeft: 8 }}>
                                                <div className='col col-md-12'>
                                                    <input type='text' className='form-control' placeholder='Nom(s) *'
                                                        value={nom} onChange={handleInputNom}
                                                        style={{ fontWeight: 600, fontSize: 12, height: 30 }}
                                                    />
                                                    {message_nom !== "" ? <label style={{ color: 'red' }}>{message_nom}</label> : ""}

                                                </div>
                                            </div>
                                            <div className='row' style={{ marginLeft: 8 }}>

                                                <div className='col col-md-12'>
                                                    <input type='text' className='form-control' placeholder='Prenom(s) '
                                                        value={prenom} onChange={handleInputPrenom}
                                                        style={{ fontWeight: 600, fontSize: 12, height: 30 }}
                                                    />


                                                </div>


                                            </div>
                                            <div className='row' style={{ marginLeft: 8 }}>
                                                <div className='col col-md-2'>
                                                    <select className='form-control' value={sexe} onChange={handleInputSexe}
                                                        style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                        <option value={""}>Sexe *</option>
                                                        <option value='M'>M</option>
                                                        <option value='F'>F</option>
                                                    </select>
                                                    {message_sexe !== "" ? <label style={{ color: 'red' }}>{message_sexe}</label> : ""}

                                                </div>
                                                <div className='col col-md-5'>

                                                    <input type='text' className='form-control' placeholder='E-mail *'
                                                        value={email} onChange={handleInputEmail} style={{ fontWeight: 600, fontSize: 12, height: 30 }}
                                                    />
                                                    {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}

                                                </div>
                                                <div className='col col-md-5'>

                                                    <input
                                                        className='form-control'
                                                        value={telephone}
                                                        onChange={(e) => handleInputTelephone(e.target.value)}
                                                        placeholder="Téléphone * "
                                                        style={{ fontWeight: 600, fontSize: 12, height: 30 }}

                                                    />

                                                    {message_telephone !== "" ? <label style={{ color: 'red' }}>{message_telephone}</label> : ""}

                                                </div>
                                            </div>

                                        </div>
                                        <div className='col col-md-1'>

                                        </div>
                                        <div className="col col-md-3">
                                            <div style={{ width: "90%", height: '150px', border: "1px solid grey", display: "flex", justifyContent: "center" }}>
                                                <img src={avatarImage !== null ? avatarSetImage : "https://www.shutterstock.com/image-vector/camera-add-icon-600nw-1054194038.jpg"}
                                                    style={{ width: "100%", marginLeft: 0, height: "100%" }}
                                                    onClick={() => HandleAvatar()}
                                                    title={'Veuillez cliquer pour sélectionner la photo'}
                                                />
                                            </div>

                                            <input type='file'
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                onChange={handleFileChangeAvatar}
                                                ref={useRefImage}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="flex py-4">
                                        <Button label="Next" className='btn btn-info' icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                                    </div> */}
                                </StepperPanel>
                                <StepperPanel header="Informations professionnelles" >
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className="col col-md-4">
                                            <select className='form-control' value={qualification} onChange={(e) => handleInputQualification(e)}
                                                style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                <option value={""}>{"Qualification *"}</option>
                                                <option value='Médecin'>Médecin</option>
                                                <option value='Professeur agrégé'>Professeur agrégé</option>
                                            </select>
                                            {message_qualification !== "" ? <label style={{ color: 'red' }}>{message_qualification}</label> : ""}

                                        </div>
                                        <div className="col col-md-4">
                                            <select className='form-control' value={specialite} onChange={handleInputSpecialite}
                                                style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                <option value={""}>Spécialité *</option>
                                                <option value='Radiologue'>Radiologue</option>

                                            </select>
                                            {message_specialite !== "" ? <label style={{ color: 'red' }}>{message_specialite}</label> : ""}

                                        </div>
                                        <div className="col col-md-4">
                                            <select className='form-control' value={experience} onChange={(e) => setExperience(e.target.value)}
                                                style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                <option value={""}>{"Expérience *"}</option>
                                                {experiences.map((exp) => (
                                                    <option value={exp.label}>{exp.label}</option>
                                                ))}

                                            </select>

                                            {message_experience !== "" ? <label style={{ color: 'red' }}>{message_experience}</label> : ""}

                                        </div>

                                    </div>
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className='col col-md-12' style={{ display: "flex" }}>
                                            <h6 style={{}}>CV:</h6>
                                            <input type="file" accept=".pdf,application/pdf" onChange={(e) => selectedCv(e)} id="fileInput" style={{ marginLeft: 5, marginTop: 5 }} class="file-input" />

                                            {/* {cv && <i className='fa fa-download' onClick={(e) => handleDownload(e, cv)} title='Télécharger votre cv' style={{ fontSize: 20, float: 'right', cursor: "pointer", marginTop: 5 }}></i>} */}

                                        </div>
                                    </div>
                                    {/* <div className="flex py-4 gap-2">
                                        <Button label="Back" className='btn btn-default' severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                        <Button label="Next" className='btn btn-info' icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                                    </div> */}
                                </StepperPanel>

                                <StepperPanel header="Informations de localisation">
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className="col col-md-6">
                                            <select className='form-control' value={pays} onChange={handleCountryChange}
                                                style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                <option value={""}>{"Sélectionner un pays *"} </option>
                                                {countries.map((country) => (
                                                    <option key={country.isoCode} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_pays !== "" ? <label style={{ color: 'red' }}>{message_pays}</label> : ""}

                                        </div>
                                        <div className="col col-md-6">
                                            <select className='form-control' value={departement} onChange={handleInputVille}
                                                style={{ fontWeight: 600, fontSize: 12, height: 30 }}>
                                                <option value={""}>{"Sélectionner une ville"}</option>
                                                {region.map((region) => (
                                                    <option key={region.isoCode} value={region.name}>
                                                        {region.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_departement !== "" ? <label style={{ color: 'red' }}>{message_departement}</label> : ""}

                                        </div>
                                    </div>
                                    {/* <div className="flex py-4 gap-2">
                                        <Button label="Back" className='btn btn-default' severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                        <Button label="Next" className='btn btn-info' icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                                    </div> */}
                                </StepperPanel>
                                <StepperPanel header="Boigraphie">
                                    <div className='row'>
                                        <div className='col col-md-12' style={{ marginLeft: '-30px' }}>
                                            <textarea rows={10} className='form-control' placeholder='Biographie' value={biographie} onChange={(e) => setBiographie(e.target.value)}>

                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="flex py-4">
                                        {/* <Button label="Back" className='btn btn-default' severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} /> */}
                                    </div>
                                </StepperPanel>
                            </Stepper>

                        </div>
                        <div className='card' style={{ height: 50, display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => addMembre()} style={{ marginTop: 10, position: 'absolute' }} type='button' disabled={boolUnblockBtn} className='btn btn-info'>Enregistrer</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default MembreInfoAdd
