import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import { ThemeContext } from '../context/ContextHeader';
import { URL_AXIOS } from '../URL/URL';
import axios from 'axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Tag } from 'primereact/tag';
import { BiLogOutCircle } from "react-icons/bi";
import reactSessionApi from 'react-session-api';

function Header() {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('top');
    const [bool, setBool] = useState(true);
    const [ingredients, setIngredients] = useState([]);
    const [membres, setMembres] = useState([]);
    const [visibleOptions, setVisibleOptions] = useState(false);
    // const membre_id = sessionStorage.getItem("membre_id");
    // const site_id = sessionStorage.getItem("site_id");
    const usernameconnected = sessionStorage.getItem("nom");
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const [exp, setExp] = useState("");
    const [etat, setEtat] = useState("");
    const [textSearch, setTextSearch] = useState("");

    const {
        items, setItems,
        disable, setDisable,
        disableAvis, setDisableAvis,
        dataAvisSend, setDataAvisSend,
        setNbFolderAvisSend,
        dataAvisReceive, setDataAvisReceive,
        medecinID, setMedecinID,
        datedebut, setDatedebut,
        dossiersEn, setDossiersEn,
        dossiers, setDossiers,
        datefin, setDateFin,
        nbFoldersSend, setNbFoldersSend,
        nbFoldersArchive, setNbFoldersArchive,
        notifi, setNotifi,
        notifiLimit, setNotifiLimit,
        objet, setObjet,
        contenu, setContenu,
        name_recep, setName_recep,
        societe_recep, setSociete_recep,
        fichier_notification, setFichier_notification,
        created_at, setCreated_at,
        name_sender, setName_sender,
        societe_sender, setSociete_sender,
        dataMembre, setDataMembre,
        getURLFolder, setGetURLFolder,
        notifiRecep, setNotifiRecep,
        nbFolderAvisReceive,
        userID, setUserID,
        notifications, setNotifications,
        disableRefresh, setDisableRefresh,
        dossiersArchive, setDossiersArchive,
        nbFolderAvisSend,
        dataExamenStatistique, setDataExamenStatistique,
        periodeContext, setPeriodeContext,
        dossiersCorbeille, setDossiersCorbeille,
        examen_total, setExamen_total,
        setNbFolderAvisReceive, setMcr,
        titles, setTitles,
        toggleAvis,
        disableOption, setDisableOption
    } = useContext(ThemeContext);
    const location = useLocation();
    const currentLocation = location.pathname.slice(0, -2);
    //const etat=sessionStorage.getItem("etat");
    //const exp=sessionStorage.getItem("exp");



    const LogOut = () => {
        sessionStorage.removeItem("nom");
        sessionStorage.removeItem("membre_id");
        sessionStorage.removeItem("user_id");
        navigate('/')

    }

    const handleQueryTextMedecin = async (e) => {
        try {
            const query_name = e.target.value;
            setTextSearch(query_name);
            if (query_name.length > 0) {
                const search_data = membres.filter(item =>
                    item.nom.toLowerCase().includes(textSearch.toLowerCase()) ||
                    item.prenom.toLowerCase().includes(textSearch.toLowerCase())

                );

                setMembres(search_data);

            } else {
                liste_membre();
            }
        } catch (error) {

        }
    }
    const liste_notificationByUsers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${userID}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }

    const liste_membreinfo = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `get_membre_by_id/${membre_id}`);
            if (response.data.status === 200) {

                setExp(response.data.membres.exp);
                setEtat(response.data.membres.etat);

            }
        } catch (Error) {

        }
    }

    const confirm1 = () => {
        const question = location.pathname == "/resultat" ? "Voulez-vous supprimer ce dossier ?" : "Voulez-vous restaurer ce dossier";
        const message = location.pathname == "/resultat" ? "Le dossier a été supprimé avec succès!" : "Le dossier a été restauré avec succès !";
        if (items !== 0) {

            Swal.fire({
                title: question,
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                icon: 'question'
            }).then((result) => {

                if (result.isConfirmed) {
                    retirer_dossier();

                } else if (result.isCanceled) {

                }
            });


            /* confirmDialog({
                 message: message,
                 header: 'Confirmation',
                 icon: 'pi pi-exclamation-triangle',
                 accept: () => {
                     retirer_dossier()
                 },
                 reject: () => {
 
                 }
             });*/

        } else {
            alert("Veuillez cocher le médecin")
        }

    };

    const retirer_dossier = async () => {
        try {
            if (items.length > 0) {
                if (location.pathname == "/corbeille") {
                    for (const val of items) {
                        const response = await axios.post(URL_AXIOS + `restaurer_dossier/${val}`);
                        if (response.data.status == 200) {
                            liste_dossiers_by_siteEnteteAccordion_retire(parseInt(membre_id));
                            liste_dossiers_by_siteEnteteAccordion(membre_id);

                        }

                    }
                    setItems([]);
                } else if (location.pathname == "/resultat") {
                    for (const val of items) {
                        const response = await axios.post(URL_AXIOS + `retirer_dossier/${val}`);
                        if (response.data.status == 200) {
                            liste_dossiers_by_siteEnteteAccordion(membre_id);
                            liste_dossiers_by_siteEnteteAccordion_retire(membre_id);
                        }

                    }
                    setItems([]);
                } else if (location.pathname == "/archives") {
                    for (const val of items) {

                        const response = await axios.post(URL_AXIOS + `restaurer_dossier_interpreter/${val}`);
                        if (response.data.status === 200) {
                            liste_dossiers_by_siteEnteteAccordion(membre_id);
                            liste_dossiers_by_siteEnteteAccordionArchive(membre_id);



                        }

                    }
                    setItems([]);
                }

            } else {
                //alert("Empty")
            }



        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const liste_membre = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `listMembreById/${medecinID}`);
            if (response.data.status === 200) {
                setMembres(response.data.membres);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const AlertSave = () => {
        Swal.fire({
            title: 'Informations',
            text: 'Les données ont été enregistrées avec succès !',
            icon: 'success',

        })
    }
    const liste_dossiers_avis_placeholder = async (id_membre) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_avis_sollicitePlaceHolder/${id_membre}`);
            if (response.data.status === 200) {

                const dataDossier = response.data.data_avis_solliciteByFolder;
                // const newTitles = {};
                // for (const [dossier_id, avisList] of Object.entries(dataDossier)) {
                //     newTitles[dossier_id] = avisList.map(avis =>(avis.nom_recep+' '+avis.prenom_recep)).join(', '); // Replace 'someField' with the actual field name you want to use
                // }
                setTitles(dataDossier);


            } else {
                alert("Error in API response");
            }

        } catch (error) {
            console.error("Error:", error.message);
            alert("An error occurred. Please check the console for details.");
        }
    }
    const AddAvis = async () => {
        try {
            // Create an array to accumulate data
            const requestData = [];

            for (const value of items) {
                for (const ing of ingredients) {
                    const data = {
                        dossier_id: value,
                        membre_id_send: membre_id,
                        membre_id_receive: ing
                    };

                    // Push data into the array
                    requestData.push(data);
                }
            }

            // Make a single request with all the data
            const response = await axios.post(URL_AXIOS + 'addAvis', {
                dataAvis: requestData
            });

            if (response.data.status === 200) {
                // Handle success message or data after making the request
                setItems([])
                setVisible(false);
                ListeAvisReceive(membre_id);
                ListeAvisSend(membre_id);
                liste_dossiers_by_siteEnteteAccordion(membre_id);
                liste_dossiers_avis_placeholder(membre_id);

                Swal.fire({
                    title: 'Réussi !',
                    text: "L'avis sollicité a  été envoyé avec succès !",
                    icon: 'success',

                })
            } else {
                alert("ERREUR 500 " + response.data.data);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    };
    const ListeAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status == 200) {
                    setDataAvisSend(response.data.data_avis_send);
                    setNbFolderAvisSend(response.data.data_avis_send.length)

                } else {

                }
            }

        } catch (error) {

        }
    }

    const ListeAvisReceive = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
                if (response.data.status == 200) {
                    setDataAvisReceive(response.data.data_avis_sollicite);
                    setNbFolderAvisReceive(response.data.data_avis_sollicite.length > 0 ? response.data.data_avis_sollicite.length : 0)

                } else {

                }
            }

        } catch (error) {

        }
    }


    const load_data_exams_stat = async (membre, periode) => {
        try {

            const response = await axios.get(URL_AXIOS + `load_data_exams/${membre}/${periode}`);
            if (response.data.status == 200) {
                setDataExamenStatistique(response.data.statistiqueAllData);
                setExamen_total(response.data.sommeTotalForeAllSite)

            } else {

            }


        } catch (error) {

        }
    }
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }

    const [query, setQuery] = useState("");
    const handleQueryText = async (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);

            if (location.pathname == "/resultat") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input_recherche/${query_name}/${membre_id}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiersEn(response.data.vue_dossier_send_pl_input);
                    }
                } else {
                    liste_dossiers_by_siteEnteteAccordion(membre_id);
                }
            } else if (location.pathname == "/compte_expertise") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `rechercher_solliciteAndReceive/${query_name}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        if (toggleAvis == 0) {
                            setDataAvisSend(response.data.data_avis_send);
                        } else if (toggleAvis == 1) {
                            setDataAvisReceive(response.data.data_avis_sollicite);
                        } else {

                        }




                    }
                } else {
                    ListeAvisReceive(medecinID)
                    ListeAvisSend(medecinID)
                }
            } else if (location.pathname == "/archives") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_dossiers_by_site_entete_archive/${query_name}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiersArchive(response.data.dossier_send);
                        // setDossiers(response.data.data_dossier_sub);
                        setNbFoldersArchive(response.data.data_dossier_send.length)

                    }
                } else {

                    liste_dossiers_by_siteEnteteAccordionArchive(medecinID);
                }
            } else if (location.pathname == "/corbeille") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input_recherche_retire/${query_name}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiersCorbeille(response.data.vue_dossier_send_pl_input);

                    }
                } else {
                    liste_dossiers_by_siteEnteteAccordion_retire(medecinID);
                }
            } else if (location.pathname == "/notifications") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `recherche_notification_by_objet_or_siteBySite/${user_id}/${query_name}`);
                    if (response.data.status === 200) {
                        setNotifications(response.data.notifications);
                        // setDataMembre(response.data);
                        // setGetURLFolder(response.data.folderUrl)

                    } else {

                    }
                } else {
                    liste_notification();
                }
            } else if (location.pathname == "/lireNotification") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `RechercheNotificationsLecture/${userID}/${query_name}`);
                    if (response.data.status === 200) {
                        setNotifiRecep(response.data.notifications);
                        //setDataMembre(response.data);
                        // setGetURLFolder(response.data.folderUrl)

                        console.log("Data " + response.data.notifications)

                    } else {
                        console.log("Erreur")
                    }
                } else {
                    liste_notificationByUsers();
                }
            } else if (location.pathname == "/statistiques") {
                if (query_name.length > 0) {

                    const response = await axios.post(URL_AXIOS + `recherche_in_vue_autres_statistique/${query_name}/${membre_id}/${periodeContext}`);
                    if (response.data.status === 200) {

                        setDataExamenStatistique(response.data.statistiqueAllData);
                        setExamen_total(response.data.sommeTotalForeAllSite)

                    } else {
                         load_data_exams_stat(membre_id, periodeContext)
                    }
                } else {

                }
            } else if (location.pathname == "/model_compte_rendu") {
                if (query_name.length > 0) {

                    const response = await axios.post(URL_AXIOS + `list_mcr_rechercheByMember/${membre_id}/${query_name}`);
                    if (response.data.status === 200) {
                        setMcr(response.data.mcr);
                    } else {
                        list_mcr(membre_id)
                    }
                } else {

                }
            }
        } catch (error) {
        }
    };

    const list_mcr = async (membre_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcrByMember/${membre_id}`);
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
            }
        } catch (Error) {

        }
    }
    const liste_dossiers_by_siteEnteteAccordionArchive = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
            if (response.data.status == 200) {
                setDossiersArchive(response.data.dossier_send);
                // setDossiers(response.data.data_dossier_sub);
                // setNbFoldersSend(response.data.data_dossier_num.length)


            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const lireNotification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `updateStatus_notification/${id}`);
            if (response.data.status == 200) {

              
                setObjet(response.data.notification.objet);
                setContenu(response.data.notification.contenu);
                setName_recep(response.data.notification.name_recep);
                setName_sender(response.data.notification.name_sender);
                setCreated_at(response.data.notification.created_at);
                setFichier_notification(response.data.notification.fichier_notification);
                setSociete_recep(response.data.notification.societe_recep);
                liste_notificationReceptionRead();
                navigate('/lireNotification')
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const annuler_research = () => {
        setDateFin('');
        setDatedebut('');
        setBool(true);

        if (location.pathname == "/compte_expertise") {
            ListeAvisReceive(medecinID);
            ListeAvisSend(medecinID);
        } else if (location.pathname == "/archives") {
            liste_dossiers_by_siteEnteteAccordionArchive(medecinID);
        } else if (location.pathname == "/resultat") {
            liste_dossiers_by_siteEnteteAccordion(medecinID);
        } else if (location.pathname == "/corbeille") {
            liste_dossiers_by_siteEnteteAccordion_retire(medecinID);
        } else if (location.pathname == "/notifications") {
            liste_notification();
        }



    }
    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }

    const disponible_medecin = async () => {
        try {
            setVisibleOptions(false)

            Swal.fire({
                title: "Voulez-vous changer votre statut ?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Activer",
                denyButtonText: `Désactiver`
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axios.post(URL_AXIOS + `disponibilite/${membre_id}`, {
                        exp: "O"
                    });
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Changement statut",
                            text: 'Votre status a été mise à jour avec succès !',
                            icon: "success"
                        });
                        // setExp(response.data.membre.exp);

                    }
                } else if (result.isDenied) {
                    const response = await axios.post(URL_AXIOS + `disponibilite/${membre_id}`, {
                        exp: "N"
                    });
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Changement statut",
                            text: 'Votre status a été mise à jour avec succès !',
                            icon: "success"
                        });
                        //setExp(response.data.membre.exp)

                    }
                }
            });


        } catch (Error) {

        }
    }

    const activate_or_desactivate_medecin = async () => {
        try {
            setVisibleOptions(false)

            Swal.fire({
                title: "Voulez-vous changer votre statut ?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Je suis disponible",
                denyButtonText: `Je ne suis pas disponible`

            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const response = await axios.post(URL_AXIOS + `activate_or_desactivate/${membre_id}`, {
                        etat: "1"
                    });
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Changement statut",
                            text: 'Votre status a été mise à jour avec succès !',
                            icon: "success"
                        });
                        //setEtat(response.data.membre.etat);

                    }
                } else if (result.isDenied) {
                    const response = await axios.post(URL_AXIOS + `activate_or_desactivate/${membre_id}`, {
                        etat: "0"
                    });
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Changement statut",
                            text: 'Votre status a été mise à jour avec succès !',
                            icon: "success"
                        });
                        //setEtat(response.data.membre.etat);

                    }
                }
            });


        } catch (Error) {

        }
    }
    const rechercher_dossier = async () => {

        try {
            if (location.pathname == "/resultat") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_by_entete_site/${datedebut}/${datefin}/${medecinID}`);
                    if (response.data.status === 200) {
                        setDossiersEn(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/compte_expertise") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `rechercher_solliciteAndReceiveByIntervalDate/${datedebut}/${datefin}/${medecinID}`);
                    if (response.data.status === 200) {
                        if (toggleAvis == 0) {
                            setDataAvisSend(response.data.data_avis_send);
                        } else if (toggleAvis == 1) {
                            setDataAvisReceive(response.data.data_avis_sollicite);
                        }


                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/archives") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_dossiers_by_site_entete_archiveByIntervalDate/${datedebut}/${datefin}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDataAvisSend(response.data.data_avis_send);
                        //setDataAvisReceive(response.data.data_avis_sollicite);
                        setDossiersArchive(response.data.dossier_send);
                        //setDossiers(response.data.data_dossier_sub);
                        // setNbFoldersArchive(response.data.dossier_send.length)
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/corbeille") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_by_entete_site_retire/${datedebut}/${datefin}/${medecinID}`);
                    if (response.data.status === 200) {
                        setDossiersCorbeille(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/notifications") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_notificationByUsers/${datedebut}/${datefin}/${user_id}`);
                    if (response.data.status == 200) {
                        setNotifications(response.data.notifications);
                        setBool(false);
                    } else {

                    }
                } else {
                    setBool(true);
                    liste_notification();
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/model_compte_rendu") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `list_mcr_rechercheByMemberAndDateInetrval/${membre_id}/${datedebut}/${datefin}`);
                    if (response.data.status == 200) {
                        setMcr(response.data.mcr);
                        setBool(false);
                    } else {

                    }
                } else {
                    setBool(true);
                    list_mcr(membre_id);
                    alert("Veuillez remplir les deux dates svp")
                }
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
            if (response.data.status === 200) {
                setDossiersEn(response.data.dossier_send);
                //setDossiers(response.data.data_dossier_sub);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const liste_dossiers_by_siteEnteteAccordion_retire = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
            if (response.data.status == 200) {
                setDossiersCorbeille(response.data.dossier_send);
                //setDossiers(response.data.data_dossier_sub);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const optionModal = () => {
        liste_membreinfo();
        setVisibleOptions(true);
    }
    const liste_notificationReceptionRead = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
            }
        } catch (Error) {

        }
    }

    useEffect(() => {
        const validPaths = [
             "/resultat", "/compte_expertise", "/notifications", "/statistiques", 
            "/model_compte_rendu", "/archives", "/corbeille"
        ];

        if (validPaths.includes(location.pathname)) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [location.pathname]);
    useEffect(() => {
        liste_membre();
        liste_membreinfo();
        // //liste_notification();
        // if (location.pathname != "/addNotifications" || location.pathname != "/notifications") {
        //     setDisable(true)
        // }

    }, []);




    return (
        <header class="header black-bg">
            <ConfirmDialog />
            <Dialog
                header="Options"
                visible={visibleOptions}
                position={position}
                style={{ width: '50vw' }}
                onHide={() => setVisibleOptions(false)}
                draggable={false} resizable={false}>
                <Splitter style={{ height: '70px' }}>
                    <SplitterPanel className="" style={{ display: 'flex' }}>
                        <h6 style={{ marginLeft: 10, marginTop: 20 }}>MON STATUT</h6>
                        <button type='button' onClick={() => activate_or_desactivate_medecin()} className={etat == "1" ? 'btn btn-success' : 'btn btn-danger'} style={{ height: '50%', marginLeft: 10, marginTop: 10 }}>
                            {etat === '1' ? 'Je suis disponible' : 'Je ne suis pas disponible'}
                        </button>


                    </SplitterPanel>
                    <SplitterPanel style={{ display: 'flex' }}>
                        <h6 style={{ marginLeft: 10, marginTop: 20 }}>C.E.</h6>

                        <button onClick={() => disponible_medecin()} type='button' className={exp === 'O' ? 'btn btn-success' : 'btn btn-danger'} style={{ height: '50%', marginLeft: 10, marginTop: 10, width: '80%' }}>
                            {exp === "O" ? 'Activé' : 'Désactivé'}

                        </button>
                    </SplitterPanel>
                </Splitter>
            </Dialog>
            <Dialog header="Demander l'avis d'un autre medécin" visible={visible} position={position} style={{ width: '30vw', height: '90vh' }} onHide={() => setVisible(false)} draggable={false} resizable={false}>

                <input type='text' placeholder='recherche...' className='form-control'
                    value={textSearch}
                    onChange={(e) => handleQueryTextMedecin(e)}
                />
                <Divider />
                {membres.map((val) => (
                    <div className='row'>
                        <div className='col col-md-10'>
                            <h6 style={{ fontSize: 15, }}>{val.nom + " " + val.prenom}</h6>
                            <p style={{ color: 'black', fontWeight: 'bold' }}>{val.specialite + '-' + val.telephone + '-' + val.experience}</p>
                        </div>

                        <div className='col col-md-2' style={{
                            display: 'flex'
                        }} >
                            <Tag severity={val.etat === "1" ? "success" : "danger"} style={{ width: 10, height: 10, marginTop: 13 }}></Tag>
                            <Checkbox disabled={val.etat === "1" ? false : true} inputId={`dossier_${val.id}`} style={{ marginTop: 5, marginLeft: 20 }} name={val.id} value={val.id} onChange={onIngredientsChange} checked={ingredients.includes(val.id)} />

                        </div>



                        <Divider />
                    </div>


                ))}


                <button type='button' onClick={() => AddAvis()} className='btn btn-success form-control'>Envoyer</button>
            </Dialog>

            <a href="#" class="logo"><b><span style={{ color: 'red' }}>TERA</span><span>TEX</span></b></a>
            <div class="nav notify-row" id="top_menu">
                <ul class="nav top-menu">
                    <li class="dropdown" title='Dossiers reçus'>
                        <Link to={'/resultat'}>
                            <i class="fa fa-folder"></i>
                            <span class="badge bg-theme">{dossiersEn.length > 0 ? dossiersEn.length : 0}</span>
                        </Link>

                    </li>
                    <li id="header_inbox_bar" class="dropdown" title='Télé-expertise'>
                        <Link to={'/compte_expertise'}>
                            <img src="../../../img/diamond_26_whitepx.png" width="15" height="15" />&nbsp;
                            <span class="badge bg-theme">{nbFolderAvisReceive + nbFolderAvisSend}</span>
                        </Link>

                    </li>
                    <li id="header_inbox_bar" class="dropdown" title='Archives'>
                        <Link to={'/archives'}>
                            <i class="fa fa-cloud-download"></i>
                            <span class="badge bg-theme">{dossiersArchive.length > 0 ? dossiersArchive.length : 0}</span>
                        </Link>

                    </li>
                    <li id="header_notification_bar" class="dropdown" title='Notifications'>
                        <a data-toggle="dropdown" class="dropdown-toggle" href="index.html#">
                            <i class="fa fa-bell-o"></i>
                            <span class="badge bg-warning">{notifiLimit.length}</span>
                        </a>
                        <ul class="dropdown-menu extended notification">
                            <div class="notify-arrow notify-arrow-yellow"></div>
                            <li>
                                <p class="yellow">Vous avez {notifiLimit.length} notifications</p>
                            </li>
                            {
                                notifiLimit.map((noti) => (
                                    <li >
                                        <Link onClick={() => lireNotification(noti.id_notification)} style={{ color: 'black' }}>
                                            <span class="label label-success" ><i class="fa fa-envelop"></i></span>
                                            &nbsp;{noti.name_sender}<br />
                                            <span class="small italic" style={{ marginLeft: 20, marginTop: 10, color: 'black' }}>{noti.objet}</span>
                                        </Link>
                                    </li>
                                ))
                            }




                            <li>
                                <Link to={'/lireNotification'} style={{ color: "black" }}>Voir toutes les notifications</Link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
            <div class="top-menu">
                <div style={{
                    marginTop: 13,
                    position: 'absolute',
                    marginLeft: "23%",
                    display: "flex"
                }}>
                    <h6 style={{ color: 'white' }}>Date début *:</h6>
                    <input type="date" className='form-control'
                        style={{ width: "15%", marginLeft: 5 }}
                        onChange={(e) => setDatedebut(e.target.value)}
                        value={datedebut}
                        disabled={disable}
                    />
                    <h6 style={{ marginLeft: 5, color: 'white' }}>Date Fin *:</h6>
                    <input type="date"
                        className='form-control'
                        style={{ width: "15%", marginLeft: 5 }}
                        value={datefin}
                        onChange={(e) => setDateFin(e.target.value)}
                        disabled={disable}
                    />
                    <button type='button'  disabled={disable} onClick={() => bool ? rechercher_dossier() : annuler_research()} style={{ height: 35, marginLeft: 5 }} className={bool ? 'btn btn-success' : 'btn btn-danger'}>
                        <i style={{ color: 'white' }} className={bool ? 'fa fa-filter' : 'fa fa-remove'}></i>
                    </button>
                    <input type='text' className='form-control '
                        style={{
                            width: 300,
                            marginLeft: 5
                        }}
                        onChange={handleQueryText}
                        placeholder='Recherche...'
                        disabled={disable}
                    />
                    <button type='button'  disabled={disable} className='btn btn-primary' style={{ height: 35, marginLeft: 5 }} >
                        <i className='fa fa-search'></i>
                    </button>
                    <button type='button'  style={{ height: 35, marginLeft: 5 }} disabled={disableOption} onClick={confirm1} className={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'btn btn-primary' : 'btn btn-danger'} title={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'Restaurer dossier(s)' : 'Supprimer dossier(s)'}>
                        <i style={{ color: 'white' }} className={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'fa fa-refresh' : 'fa fa-trash'}></i>
                    </button>
                    <button type='button' onClick={() => setVisible(true)} disabled={disableAvis} style={{ height: 35, marginLeft: 5 }} title="Demandez un avis sur un ou plusieurs dossier(s) sélectionné(s) (Télé-expertise)" className='btn btn-success'>
                        <i style={{ color: 'white' }} className='fa fa-desktop'></i>
                    </button>
                    <button type='button' onClick={() => optionModal()}
                        style={{ height: 35, marginLeft: 5 }}
                        title="Autres options" className='btn btn-info'>
                        <i style={{ color: 'white' }} className='fa fa-user'></i>
                    </button>

                </div>
                <button type='button' className="btn btn-danger" onClick={() => LogOut()} style={{ height: 35, marginLeft: 5, float: 'right', marginTop: 12 }}>
                    <BiLogOutCircle style={{ fontSize: 20 }} />
                </button>
            </div>
        </header >
    );
}

export default Header;